/* code automatically generated by bin2c -- DO NOT EDIT */
/* #include'ing this file in a C program is equivalent to calling
  if (luaL_loadfile(L,"http.lua")==0) lua_call(L, 0, 0); 
*/
{
/* http.lua */
static const unsigned char B1[]={
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10, 45, 45,
 32, 72, 84, 84, 80, 47, 49, 46, 49, 32, 99,108,105,101,110,116, 32,115,117,112,
112,111,114,116, 32,102,111,114, 32,116,104,101, 32, 76,117, 97, 32,108, 97,110,
103,117, 97,103,101, 46, 10, 45, 45, 32, 76,117, 97, 83,111, 99,107,101,116, 32,
116,111,111,108,107,105,116, 46, 10, 45, 45, 32, 65,117,116,104,111,114, 58, 32,
 68,105,101,103,111, 32, 78,101,104, 97, 98, 10, 45, 45, 32, 82, 67, 83, 32, 73,
 68, 58, 32, 36, 73,100, 58, 32,104,116,116,112, 46,108,117, 97, 44,118, 32, 49,
 46, 55, 49, 32, 50, 48, 48, 55, 47, 49, 48, 47, 49, 51, 32, 50, 51, 58, 53, 53,
 58, 50, 48, 32,100,105,101,103,111, 32, 69,120,112, 32, 36, 10, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10, 10, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10, 45, 45, 32, 68,101, 99,108,
 97,114,101, 32,109,111,100,117,108,101, 32, 97,110,100, 32,105,109,112,111,114,
116, 32,100,101,112,101,110,100,101,110, 99,105,101,115, 10, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10,108,111, 99, 97,108,
 32,115,111, 99,107,101,116, 32, 61, 32,114,101,113,117,105,114,101, 40, 34,115,
111, 99,107,101,116, 34, 41, 10,108,111, 99, 97,108, 32,117,114,108, 32, 61, 32,
114,101,113,117,105,114,101, 40, 34,115,111, 99,107,101,116, 46,117,114,108, 34,
 41, 10,108,111, 99, 97,108, 32,108,116,110, 49, 50, 32, 61, 32,114,101,113,117,
105,114,101, 40, 34,108,116,110, 49, 50, 34, 41, 10,108,111, 99, 97,108, 32,109,
105,109,101, 32, 61, 32,114,101,113,117,105,114,101, 40, 34,109,105,109,101, 34,
 41, 10,108,111, 99, 97,108, 32,115,116,114,105,110,103, 32, 61, 32,114,101,113,
117,105,114,101, 40, 34,115,116,114,105,110,103, 34, 41, 10,108,111, 99, 97,108,
 32, 98, 97,115,101, 32, 61, 32, 95, 71, 10,108,111, 99, 97,108, 32,116, 97, 98,
108,101, 32, 61, 32,114,101,113,117,105,114,101, 40, 34,116, 97, 98,108,101, 34,
 41, 10,109,111,100,117,108,101, 40, 34,115,111, 99,107,101,116, 46,104,116,116,
112, 34, 41, 10, 10, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 10, 45, 45, 32, 80,114,111,103,114, 97,109, 32, 99,111,110,115,116, 97,
110,116,115, 10, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 10, 45, 45, 32, 99,111,110,110,101, 99,116,105,111,110, 32,116,105,109,101,
111,117,116, 32,105,110, 32,115,101, 99,111,110,100,115, 10, 84, 73, 77, 69, 79,
 85, 84, 32, 61, 32, 54, 48, 10, 45, 45, 32,100,101,102, 97,117,108,116, 32,112,
111,114,116, 32,102,111,114, 32,100,111, 99,117,109,101,110,116, 32,114,101,116,
114,105,101,118, 97,108, 10, 80, 79, 82, 84, 32, 61, 32, 56, 48, 10, 45, 45, 32,
117,115,101,114, 32, 97,103,101,110,116, 32,102,105,101,108,100, 32,115,101,110,
116, 32,105,110, 32,114,101,113,117,101,115,116, 10, 85, 83, 69, 82, 65, 71, 69,
 78, 84, 32, 61, 32,115,111, 99,107,101,116, 46, 95, 86, 69, 82, 83, 73, 79, 78,
 10, 10, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10,
 45, 45, 32, 82,101, 97,100,115, 32, 77, 73, 77, 69, 32,104,101, 97,100,101,114,
115, 32,102,114,111,109, 32, 97, 32, 99,111,110,110,101, 99,116,105,111,110, 44,
 32,117,110,102,111,108,100,105,110,103, 32,119,104,101,114,101, 32,110,101,101,
100,101,100, 10, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 10,108,111, 99, 97,108, 32,102,117,110, 99,116,105,111,110, 32,114,101, 99,
101,105,118,101,104,101, 97,100,101,114,115, 40,115,111, 99,107, 44, 32,104,101,
 97,100,101,114,115, 41, 10, 32, 32, 32, 32,108,111, 99, 97,108, 32,108,105,110,
101, 44, 32,110, 97,109,101, 44, 32,118, 97,108,117,101, 44, 32,101,114,114, 10,
 32, 32, 32, 32,104,101, 97,100,101,114,115, 32, 61, 32,104,101, 97,100,101,114,
115, 32,111,114, 32,123,125, 10, 32, 32, 32, 32, 45, 45, 32,103,101,116, 32,102,
105,114,115,116, 32,108,105,110,101, 10, 32, 32, 32, 32,108,105,110,101, 44, 32,
101,114,114, 32, 61, 32,115,111, 99,107, 58,114,101, 99,101,105,118,101, 40, 41,
 10, 32, 32, 32, 32,105,102, 32,101,114,114, 32,116,104,101,110, 32,114,101,116,
117,114,110, 32,110,105,108, 44, 32,101,114,114, 32,101,110,100, 10, 32, 32, 32,
 32, 45, 45, 32,104,101, 97,100,101,114,115, 32,103,111, 32,117,110,116,105,108,
 32, 97, 32, 98,108, 97,110,107, 32,108,105,110,101, 32,105,115, 32,102,111,117,
110,100, 10, 32, 32, 32, 32,119,104,105,108,101, 32,108,105,110,101, 32,126, 61,
 32, 34, 34, 32,100,111, 10, 32, 32, 32, 32, 32, 32, 32, 32, 45, 45, 32,103,101,
116, 32,102,105,101,108,100, 45,110, 97,109,101, 32, 97,110,100, 32,118, 97,108,
117,101, 10, 32, 32, 32, 32, 32, 32, 32, 32,110, 97,109,101, 44, 32,118, 97,108,
117,101, 32, 61, 32,115,111, 99,107,101,116, 46,115,107,105,112, 40, 50, 44, 32,
115,116,114,105,110,103, 46,102,105,110,100, 40,108,105,110,101, 44, 32, 34, 94,
 40, 46, 45, 41, 58, 37,115, 42, 40, 46, 42, 41, 34, 41, 41, 10, 32, 32, 32, 32,
 32, 32, 32, 32,105,102, 32,110,111,116, 32, 40,110, 97,109,101, 32, 97,110,100,
 32,118, 97,108,117,101, 41, 32,116,104,101,110, 32,114,101,116,117,114,110, 32,
110,105,108, 44, 32, 34,109, 97,108,102,111,114,109,101,100, 32,114,101,112,111,
110,115,101, 32,104,101, 97,100,101,114,115, 34, 32,101,110,100, 10, 32, 32, 32,
 32, 32, 32, 32, 32,110, 97,109,101, 32, 61, 32,115,116,114,105,110,103, 46,108,
111,119,101,114, 40,110, 97,109,101, 41, 10, 32, 32, 32, 32, 32, 32, 32, 32, 45,
 45, 32,103,101,116, 32,110,101,120,116, 32,108,105,110,101, 32, 40,118, 97,108,
117,101, 32,109,105,103,104,116, 32, 98,101, 32,102,111,108,100,101,100, 41, 10,
 32, 32, 32, 32, 32, 32, 32, 32,108,105,110,101, 44, 32,101,114,114, 32, 32, 61,
 32,115,111, 99,107, 58,114,101, 99,101,105,118,101, 40, 41, 10, 32, 32, 32, 32,
 32, 32, 32, 32,105,102, 32,101,114,114, 32,116,104,101,110, 32,114,101,116,117,
114,110, 32,110,105,108, 44, 32,101,114,114, 32,101,110,100, 10, 32, 32, 32, 32,
 32, 32, 32, 32, 45, 45, 32,117,110,102,111,108,100, 32, 97,110,121, 32,102,111,
108,100,101,100, 32,118, 97,108,117,101,115, 10, 32, 32, 32, 32, 32, 32, 32, 32,
119,104,105,108,101, 32,115,116,114,105,110,103, 46,102,105,110,100, 40,108,105,
110,101, 44, 32, 34, 94, 37,115, 34, 41, 32,100,111, 10, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32,118, 97,108,117,101, 32, 61, 32,118, 97,108,117,101, 32,
 46, 46, 32,108,105,110,101, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
108,105,110,101, 32, 61, 32,115,111, 99,107, 58,114,101, 99,101,105,118,101, 40,
 41, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,105,102, 32,101,114,114,
 32,116,104,101,110, 32,114,101,116,117,114,110, 32,110,105,108, 44, 32,101,114,
114, 32,101,110,100, 10, 32, 32, 32, 32, 32, 32, 32, 32,101,110,100, 10, 32, 32,
 32, 32, 32, 32, 32, 32, 45, 45, 32,115, 97,118,101, 32,112, 97,105,114, 32,105,
110, 32,116, 97, 98,108,101, 10, 32, 32, 32, 32, 32, 32, 32, 32,105,102, 32,104,
101, 97,100,101,114,115, 91,110, 97,109,101, 93, 32,116,104,101,110, 32,104,101,
 97,100,101,114,115, 91,110, 97,109,101, 93, 32, 61, 32,104,101, 97,100,101,114,
115, 91,110, 97,109,101, 93, 32, 46, 46, 32, 34, 44, 32, 34, 32, 46, 46, 32,118,
 97,108,117,101, 10, 32, 32, 32, 32, 32, 32, 32, 32,101,108,115,101, 32,104,101,
 97,100,101,114,115, 91,110, 97,109,101, 93, 32, 61, 32,118, 97,108,117,101, 32,
101,110,100, 10, 32, 32, 32, 32,101,110,100, 10, 32, 32, 32, 32,114,101,116,117,
114,110, 32,104,101, 97,100,101,114,115, 10,101,110,100, 10, 10, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10, 45, 45, 32, 69,120,116,
114, 97, 32,115,111,117,114, 99,101,115, 32, 97,110,100, 32,115,105,110,107,115,
 10, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10,115,
111, 99,107,101,116, 46,115,111,117,114, 99,101,116, 91, 34,104,116,116,112, 45,
 99,104,117,110,107,101,100, 34, 93, 32, 61, 32,102,117,110, 99,116,105,111,110,
 40,115,111, 99,107, 44, 32,104,101, 97,100,101,114,115, 41, 10, 32, 32, 32, 32,
114,101,116,117,114,110, 32, 98, 97,115,101, 46,115,101,116,109,101,116, 97,116,
 97, 98,108,101, 40,123, 10, 32, 32, 32, 32, 32, 32, 32, 32,103,101,116,102,100,
 32, 61, 32,102,117,110, 99,116,105,111,110, 40, 41, 32,114,101,116,117,114,110,
 32,115,111, 99,107, 58,103,101,116,102,100, 40, 41, 32,101,110,100, 44, 10, 32,
 32, 32, 32, 32, 32, 32, 32,100,105,114,116,121, 32, 61, 32,102,117,110, 99,116,
105,111,110, 40, 41, 32,114,101,116,117,114,110, 32,115,111, 99,107, 58,100,105,
114,116,121, 40, 41, 32,101,110,100, 10, 32, 32, 32, 32,125, 44, 32,123, 10, 32,
 32, 32, 32, 32, 32, 32, 32, 95, 95, 99, 97,108,108, 32, 61, 32,102,117,110, 99,
116,105,111,110, 40, 41, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 45,
 45, 32,103,101,116, 32, 99,104,117,110,107, 32,115,105,122,101, 44, 32,115,107,
105,112, 32,101,120,116,101,110,116,105,111,110, 10, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32,108,111, 99, 97,108, 32,108,105,110,101, 44, 32,101,114,114,
 32, 61, 32,115,111, 99,107, 58,114,101, 99,101,105,118,101, 40, 41, 10, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,105,102, 32,101,114,114, 32,116,104,101,
110, 32,114,101,116,117,114,110, 32,110,105,108, 44, 32,101,114,114, 32,101,110,
100, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,108,111, 99, 97,108, 32,
115,105,122,101, 32, 61, 32, 98, 97,115,101, 46,116,111,110,117,109, 98,101,114,
 40,115,116,114,105,110,103, 46,103,115,117, 98, 40,108,105,110,101, 44, 32, 34,
 59, 46, 42, 34, 44, 32, 34, 34, 41, 44, 32, 49, 54, 41, 10, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32,105,102, 32,110,111,116, 32,115,105,122,101, 32,116,
104,101,110, 32,114,101,116,117,114,110, 32,110,105,108, 44, 32, 34,105,110,118,
 97,108,105,100, 32, 99,104,117,110,107, 32,115,105,122,101, 34, 32,101,110,100,
 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 45, 45, 32,119, 97,115, 32,
105,116, 32,116,104,101, 32,108, 97,115,116, 32, 99,104,117,110,107, 63, 10, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,105,102, 32,115,105,122,101, 32, 62,
 32, 48, 32,116,104,101,110, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 45, 45, 32,105,102, 32,110,111,116, 44, 32,103,101,116, 32, 99,
104,117,110,107, 32, 97,110,100, 32,115,107,105,112, 32,116,101,114,109,105,110,
 97,116,105,110,103, 32, 67, 82, 76, 70, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32,108,111, 99, 97,108, 32, 99,104,117,110,107, 44, 32,
101,114,114, 44, 32,112, 97,114,116, 32, 61, 32,115,111, 99,107, 58,114,101, 99,
101,105,118,101, 40,115,105,122,101, 41, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32,105,102, 32, 99,104,117,110,107, 32,116,104,101,110,
 32,115,111, 99,107, 58,114,101, 99,101,105,118,101, 40, 41, 32,101,110,100, 10,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,114,101,116,117,
114,110, 32, 99,104,117,110,107, 44, 32,101,114,114, 10, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32,101,108,115,101, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 45, 45, 32,105,102, 32,105,116, 32,119, 97,115, 44,
 32,114,101, 97,100, 32,116,114, 97,105,108,101,114,115, 32,105,110,116,111, 32,
104,101, 97,100,101,114,115, 32,116, 97, 98,108,101, 10, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,104,101, 97,100,101,114,115, 44, 32,101,
114,114, 32, 61, 32,114,101, 99,101,105,118,101,104,101, 97,100,101,114,115, 40,
115,111, 99,107, 44, 32,104,101, 97,100,101,114,115, 41, 10, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,105,102, 32,110,111,116, 32,104,101,
 97,100,101,114,115, 32,116,104,101,110, 32,114,101,116,117,114,110, 32,110,105,
108, 44, 32,101,114,114, 32,101,110,100, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32,101,110,100, 10, 32, 32, 32, 32, 32, 32, 32, 32,101,110,100, 10, 32,
 32, 32, 32,125, 41, 10,101,110,100, 10, 10,115,111, 99,107,101,116, 46,115,105,
110,107,116, 91, 34,104,116,116,112, 45, 99,104,117,110,107,101,100, 34, 93, 32,
 61, 32,102,117,110, 99,116,105,111,110, 40,115,111, 99,107, 41, 10, 32, 32, 32,
 32,114,101,116,117,114,110, 32, 98, 97,115,101, 46,115,101,116,109,101,116, 97,
116, 97, 98,108,101, 40,123, 10, 32, 32, 32, 32, 32, 32, 32, 32,103,101,116,102,
100, 32, 61, 32,102,117,110, 99,116,105,111,110, 40, 41, 32,114,101,116,117,114,
110, 32,115,111, 99,107, 58,103,101,116,102,100, 40, 41, 32,101,110,100, 44, 10,
 32, 32, 32, 32, 32, 32, 32, 32,100,105,114,116,121, 32, 61, 32,102,117,110, 99,
116,105,111,110, 40, 41, 32,114,101,116,117,114,110, 32,115,111, 99,107, 58,100,
105,114,116,121, 40, 41, 32,101,110,100, 10, 32, 32, 32, 32,125, 44, 32,123, 10,
 32, 32, 32, 32, 32, 32, 32, 32, 95, 95, 99, 97,108,108, 32, 61, 32,102,117,110,
 99,116,105,111,110, 40,115,101,108,102, 44, 32, 99,104,117,110,107, 44, 32,101,
114,114, 41, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,105,102, 32,110,
111,116, 32, 99,104,117,110,107, 32,116,104,101,110, 32,114,101,116,117,114,110,
 32,115,111, 99,107, 58,115,101,110,100, 40, 34, 48, 92,114, 92,110, 92,114, 92,
110, 34, 41, 32,101,110,100, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
108,111, 99, 97,108, 32,115,105,122,101, 32, 61, 32,115,116,114,105,110,103, 46,
102,111,114,109, 97,116, 40, 34, 37, 88, 92,114, 92,110, 34, 44, 32,115,116,114,
105,110,103, 46,108,101,110, 40, 99,104,117,110,107, 41, 41, 10, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32,114,101,116,117,114,110, 32,115,111, 99,107, 58,
115,101,110,100, 40,115,105,122,101, 32, 46, 46, 32, 32, 99,104,117,110,107, 32,
 46, 46, 32, 34, 92,114, 92,110, 34, 41, 10, 32, 32, 32, 32, 32, 32, 32, 32,101,
110,100, 10, 32, 32, 32, 32,125, 41, 10,101,110,100, 10, 10, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10, 45, 45, 32, 76,111,119, 32,
108,101,118,101,108, 32, 72, 84, 84, 80, 32, 65, 80, 73, 10, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10,108,111, 99, 97,108, 32,109,
101,116, 97,116, 32, 61, 32,123, 32, 95, 95,105,110,100,101,120, 32, 61, 32,123,
125, 32,125, 10, 10,102,117,110, 99,116,105,111,110, 32,111,112,101,110, 40,104,
111,115,116, 44, 32,112,111,114,116, 44, 32, 99,114,101, 97,116,101, 41, 10, 32,
 32, 32, 32, 45, 45, 32, 99,114,101, 97,116,101, 32,115,111, 99,107,101,116, 32,
119,105,116,104, 32,117,115,101,114, 32, 99,111,110,110,101, 99,116, 32,102,117,
110, 99,116,105,111,110, 44, 32,111,114, 32,119,105,116,104, 32,100,101,102, 97,
117,108,116, 10, 32, 32, 32, 32,108,111, 99, 97,108, 32, 99, 32, 61, 32,115,111,
 99,107,101,116, 46,116,114,121, 40, 40, 99,114,101, 97,116,101, 32,111,114, 32,
115,111, 99,107,101,116, 46,116, 99,112, 41, 40, 41, 41, 10, 32, 32, 32, 32,108,
111, 99, 97,108, 32,104, 32, 61, 32, 98, 97,115,101, 46,115,101,116,109,101,116,
 97,116, 97, 98,108,101, 40,123, 32, 99, 32, 61, 32, 99, 32,125, 44, 32,109,101,
116, 97,116, 41, 10, 32, 32, 32, 32, 45, 45, 32, 99,114,101, 97,116,101, 32,102,
105,110, 97,108,105,122,101,100, 32,116,114,121, 10, 32, 32, 32, 32,104, 46,116,
114,121, 32, 61, 32,115,111, 99,107,101,116, 46,110,101,119,116,114,121, 40,102,
117,110, 99,116,105,111,110, 40, 41, 32,104, 58, 99,108,111,115,101, 40, 41, 32,
101,110,100, 41, 10, 32, 32, 32, 32, 45, 45, 32,115,101,116, 32,116,105,109,101,
111,117,116, 32, 98,101,102,111,114,101, 32, 99,111,110,110,101, 99,116,105,110,
103, 10, 32, 32, 32, 32,104, 46,116,114,121, 40, 99, 58,115,101,116,116,105,109,
101,111,117,116, 40, 84, 73, 77, 69, 79, 85, 84, 41, 41, 10, 32, 32, 32, 32,104,
 46,116,114,121, 40, 99, 58, 99,111,110,110,101, 99,116, 40,104,111,115,116, 44,
 32,112,111,114,116, 32,111,114, 32, 80, 79, 82, 84, 41, 41, 10, 32, 32, 32, 32,
 45, 45, 32,104,101,114,101, 32,101,118,101,114,121,116,104,105,110,103, 32,119,
111,114,107,101,100, 10, 32, 32, 32, 32,114,101,116,117,114,110, 32,104, 10,101,
110,100, 10, 10,102,117,110, 99,116,105,111,110, 32,109,101,116, 97,116, 46, 95,
 95,105,110,100,101,120, 58,115,101,110,100,114,101,113,117,101,115,116,108,105,
110,101, 40,109,101,116,104,111,100, 44, 32,117,114,105, 41, 10, 32, 32, 32, 32,
108,111, 99, 97,108, 32,114,101,113,108,105,110,101, 32, 61, 32,115,116,114,105,
110,103, 46,102,111,114,109, 97,116, 40, 34, 37,115, 32, 37,115, 32, 72, 84, 84,
 80, 47, 49, 46, 49, 92,114, 92,110, 34, 44, 32,109,101,116,104,111,100, 32,111,
114, 32, 34, 71, 69, 84, 34, 44, 32,117,114,105, 41, 10, 32, 32, 32, 32,114,101,
116,117,114,110, 32,115,101,108,102, 46,116,114,121, 40,115,101,108,102, 46, 99,
 58,115,101,110,100, 40,114,101,113,108,105,110,101, 41, 41, 10,101,110,100, 10,
 10,102,117,110, 99,116,105,111,110, 32,109,101,116, 97,116, 46, 95, 95,105,110,
100,101,120, 58,115,101,110,100,104,101, 97,100,101,114,115, 40,104,101, 97,100,
101,114,115, 41, 10, 32, 32, 32, 32,108,111, 99, 97,108, 32,104, 32, 61, 32, 34,
 92,114, 92,110, 34, 10, 32, 32, 32, 32,102,111,114, 32,105, 44, 32,118, 32,105,
110, 32, 98, 97,115,101, 46,112, 97,105,114,115, 40,104,101, 97,100,101,114,115,
 41, 32,100,111, 10, 32, 32, 32, 32, 32, 32, 32, 32,104, 32, 61, 32,105, 32, 46,
 46, 32, 34, 58, 32, 34, 32, 46, 46, 32,118, 32, 46, 46, 32, 34, 92,114, 92,110,
 34, 32, 46, 46, 32,104, 10, 32, 32, 32, 32,101,110,100, 10, 32, 32, 32, 32,115,
101,108,102, 46,116,114,121, 40,115,101,108,102, 46, 99, 58,115,101,110,100, 40,
104, 41, 41, 10, 32, 32, 32, 32,114,101,116,117,114,110, 32, 49, 10,101,110,100,
 10, 10,102,117,110, 99,116,105,111,110, 32,109,101,116, 97,116, 46, 95, 95,105,
110,100,101,120, 58,115,101,110,100, 98,111,100,121, 40,104,101, 97,100,101,114,
115, 44, 32,115,111,117,114, 99,101, 44, 32,115,116,101,112, 41, 10, 32, 32, 32,
 32,115,111,117,114, 99,101, 32, 61, 32,115,111,117,114, 99,101, 32,111,114, 32,
108,116,110, 49, 50, 46,115,111,117,114, 99,101, 46,101,109,112,116,121, 40, 41,
 10, 32, 32, 32, 32,115,116,101,112, 32, 61, 32,115,116,101,112, 32,111,114, 32,
108,116,110, 49, 50, 46,112,117,109,112, 46,115,116,101,112, 10, 32, 32, 32, 32,
 45, 45, 32,105,102, 32,119,101, 32,100,111,110, 39,116, 32,107,110,111,119, 32,
116,104,101, 32,115,105,122,101, 32,105,110, 32, 97,100,118, 97,110, 99,101, 44,
 32,115,101,110,100, 32, 99,104,117,110,107,101,100, 32, 97,110,100, 32,104,111,
112,101, 32,102,111,114, 32,116,104,101, 32, 98,101,115,116, 10, 32, 32, 32, 32,
108,111, 99, 97,108, 32,109,111,100,101, 32, 61, 32, 34,104,116,116,112, 45, 99,
104,117,110,107,101,100, 34, 10, 32, 32, 32, 32,105,102, 32,104,101, 97,100,101,
114,115, 91, 34, 99,111,110,116,101,110,116, 45,108,101,110,103,116,104, 34, 93,
 32,116,104,101,110, 32,109,111,100,101, 32, 61, 32, 34,107,101,101,112, 45,111,
112,101,110, 34, 32,101,110,100, 10, 32, 32, 32, 32,114,101,116,117,114,110, 32,
115,101,108,102, 46,116,114,121, 40,108,116,110, 49, 50, 46,112,117,109,112, 46,
 97,108,108, 40,115,111,117,114, 99,101, 44, 32,115,111, 99,107,101,116, 46,115,
105,110,107, 40,109,111,100,101, 44, 32,115,101,108,102, 46, 99, 41, 44, 32,115,
116,101,112, 41, 41, 10,101,110,100, 10, 10,102,117,110, 99,116,105,111,110, 32,
109,101,116, 97,116, 46, 95, 95,105,110,100,101,120, 58,114,101, 99,101,105,118,
101,115,116, 97,116,117,115,108,105,110,101, 40, 41, 10, 32, 32, 32, 32,108,111,
 99, 97,108, 32,115,116, 97,116,117,115, 32, 61, 32,115,101,108,102, 46,116,114,
121, 40,115,101,108,102, 46, 99, 58,114,101, 99,101,105,118,101, 40, 53, 41, 41,
 10, 32, 32, 32, 32, 45, 45, 32,105,100,101,110,116,105,102,121, 32, 72, 84, 84,
 80, 47, 48, 46, 57, 32,114,101,115,112,111,110,115,101,115, 44, 32,119,104,105,
 99,104, 32,100,111, 32,110,111,116, 32, 99,111,110,116, 97,105,110, 32, 97, 32,
115,116, 97,116,117,115, 32,108,105,110,101, 10, 32, 32, 32, 32, 45, 45, 32,116,
104,105,115, 32,105,115, 32,106,117,115,116, 32, 97, 32,104,101,117,114,105,115,
116,105, 99, 44, 32, 98,117,116, 32,105,115, 32,119,104, 97,116, 32,116,104,101,
 32, 82, 70, 67, 32,114,101, 99,111,109,109,101,110,100,115, 10, 32, 32, 32, 32,
105,102, 32,115,116, 97,116,117,115, 32,126, 61, 32, 34, 72, 84, 84, 80, 47, 34,
 32,116,104,101,110, 32,114,101,116,117,114,110, 32,110,105,108, 44, 32,115,116,
 97,116,117,115, 32,101,110,100, 10, 32, 32, 32, 32, 45, 45, 32,111,116,104,101,
114,119,105,115,101, 32,112,114,111, 99,101,101,100, 32,114,101, 97,100,105,110,
103, 32, 97, 32,115,116, 97,116,117,115, 32,108,105,110,101, 10, 32, 32, 32, 32,
115,116, 97,116,117,115, 32, 61, 32,115,101,108,102, 46,116,114,121, 40,115,101,
108,102, 46, 99, 58,114,101, 99,101,105,118,101, 40, 34, 42,108, 34, 44, 32,115,
116, 97,116,117,115, 41, 41, 10, 32, 32, 32, 32,108,111, 99, 97,108, 32, 99,111,
100,101, 32, 61, 32,115,111, 99,107,101,116, 46,115,107,105,112, 40, 50, 44, 32,
115,116,114,105,110,103, 46,102,105,110,100, 40,115,116, 97,116,117,115, 44, 32,
 34, 72, 84, 84, 80, 47, 37,100, 42, 37, 46, 37,100, 42, 32, 40, 37,100, 37,100,
 37,100, 41, 34, 41, 41, 10, 32, 32, 32, 32,114,101,116,117,114,110, 32,115,101,
108,102, 46,116,114,121, 40, 98, 97,115,101, 46,116,111,110,117,109, 98,101,114,
 40, 99,111,100,101, 41, 44, 32,115,116, 97,116,117,115, 41, 10,101,110,100, 10,
 10,102,117,110, 99,116,105,111,110, 32,109,101,116, 97,116, 46, 95, 95,105,110,
100,101,120, 58,114,101, 99,101,105,118,101,104,101, 97,100,101,114,115, 40, 41,
 10, 32, 32, 32, 32,114,101,116,117,114,110, 32,115,101,108,102, 46,116,114,121,
 40,114,101, 99,101,105,118,101,104,101, 97,100,101,114,115, 40,115,101,108,102,
 46, 99, 41, 41, 10,101,110,100, 10, 10,102,117,110, 99,116,105,111,110, 32,109,
101,116, 97,116, 46, 95, 95,105,110,100,101,120, 58,114,101, 99,101,105,118,101,
 98,111,100,121, 40,104,101, 97,100,101,114,115, 44, 32,115,105,110,107, 44, 32,
115,116,101,112, 41, 10, 32, 32, 32, 32,115,105,110,107, 32, 61, 32,115,105,110,
107, 32,111,114, 32,108,116,110, 49, 50, 46,115,105,110,107, 46,110,117,108,108,
 40, 41, 10, 32, 32, 32, 32,115,116,101,112, 32, 61, 32,115,116,101,112, 32,111,
114, 32,108,116,110, 49, 50, 46,112,117,109,112, 46,115,116,101,112, 10, 32, 32,
 32, 32,108,111, 99, 97,108, 32,108,101,110,103,116,104, 32, 61, 32, 98, 97,115,
101, 46,116,111,110,117,109, 98,101,114, 40,104,101, 97,100,101,114,115, 91, 34,
 99,111,110,116,101,110,116, 45,108,101,110,103,116,104, 34, 93, 41, 10, 32, 32,
 32, 32,108,111, 99, 97,108, 32,116, 32, 61, 32,104,101, 97,100,101,114,115, 91,
 34,116,114, 97,110,115,102,101,114, 45,101,110, 99,111,100,105,110,103, 34, 93,
 32, 45, 45, 32,115,104,111,114,116, 99,117,116, 10, 32, 32, 32, 32,108,111, 99,
 97,108, 32,109,111,100,101, 32, 61, 32, 34,100,101,102, 97,117,108,116, 34, 32,
 45, 45, 32, 99,111,110,110,101, 99,116,105,111,110, 32, 99,108,111,115,101, 10,
 32, 32, 32, 32,105,102, 32,116, 32, 97,110,100, 32,116, 32,126, 61, 32, 34,105,
100,101,110,116,105,116,121, 34, 32,116,104,101,110, 32,109,111,100,101, 32, 61,
 32, 34,104,116,116,112, 45, 99,104,117,110,107,101,100, 34, 10, 32, 32, 32, 32,
101,108,115,101,105,102, 32, 98, 97,115,101, 46,116,111,110,117,109, 98,101,114,
 40,104,101, 97,100,101,114,115, 91, 34, 99,111,110,116,101,110,116, 45,108,101,
110,103,116,104, 34, 93, 41, 32,116,104,101,110, 32,109,111,100,101, 32, 61, 32,
 34, 98,121, 45,108,101,110,103,116,104, 34, 32,101,110,100, 10, 32, 32, 32, 32,
114,101,116,117,114,110, 32,115,101,108,102, 46,116,114,121, 40,108,116,110, 49,
 50, 46,112,117,109,112, 46, 97,108,108, 40,115,111, 99,107,101,116, 46,115,111,
117,114, 99,101, 40,109,111,100,101, 44, 32,115,101,108,102, 46, 99, 44, 32,108,
101,110,103,116,104, 41, 44, 10, 32, 32, 32, 32, 32, 32, 32, 32,115,105,110,107,
 44, 32,115,116,101,112, 41, 41, 10,101,110,100, 10, 10,102,117,110, 99,116,105,
111,110, 32,109,101,116, 97,116, 46, 95, 95,105,110,100,101,120, 58,114,101, 99,
101,105,118,101, 48, 57, 98,111,100,121, 40,115,116, 97,116,117,115, 44, 32,115,
105,110,107, 44, 32,115,116,101,112, 41, 10, 32, 32, 32, 32,108,111, 99, 97,108,
 32,115,111,117,114, 99,101, 32, 61, 32,108,116,110, 49, 50, 46,115,111,117,114,
 99,101, 46,114,101,119,105,110,100, 40,115,111, 99,107,101,116, 46,115,111,117,
114, 99,101, 40, 34,117,110,116,105,108, 45, 99,108,111,115,101,100, 34, 44, 32,
115,101,108,102, 46, 99, 41, 41, 10, 32, 32, 32, 32,115,111,117,114, 99,101, 40,
115,116, 97,116,117,115, 41, 10, 32, 32, 32, 32,114,101,116,117,114,110, 32,115,
101,108,102, 46,116,114,121, 40,108,116,110, 49, 50, 46,112,117,109,112, 46, 97,
108,108, 40,115,111,117,114, 99,101, 44, 32,115,105,110,107, 44, 32,115,116,101,
112, 41, 41, 10,101,110,100, 10, 10,102,117,110, 99,116,105,111,110, 32,109,101,
116, 97,116, 46, 95, 95,105,110,100,101,120, 58, 99,108,111,115,101, 40, 41, 10,
 32, 32, 32, 32,114,101,116,117,114,110, 32,115,101,108,102, 46, 99, 58, 99,108,
111,115,101, 40, 41, 10,101,110,100, 10, 10, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 10, 45, 45, 32, 72,105,103,104, 32,108,101,118,
101,108, 32, 72, 84, 84, 80, 32, 65, 80, 73, 10, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 10,108,111, 99, 97,108, 32,102,117,110, 99,
116,105,111,110, 32, 97,100,106,117,115,116,117,114,105, 40,114,101,113,116, 41,
 10, 32, 32, 32, 32,108,111, 99, 97,108, 32,117, 32, 61, 32,114,101,113,116, 10,
 32, 32, 32, 32, 45, 45, 32,105,102, 32,116,104,101,114,101, 32,105,115, 32, 97,
 32,112,114,111,120,121, 44, 32,119,101, 32,110,101,101,100, 32,116,104,101, 32,
102,117,108,108, 32,117,114,108, 46, 32,111,116,104,101,114,119,105,115,101, 44,
 32,106,117,115,116, 32, 97, 32,112, 97,114,116, 46, 10, 32, 32, 32, 32,105,102,
 32,110,111,116, 32,114,101,113,116, 46,112,114,111,120,121, 32, 97,110,100, 32,
110,111,116, 32, 80, 82, 79, 88, 89, 32,116,104,101,110, 10, 32, 32, 32, 32, 32,
 32, 32, 32,117, 32, 61, 32,123, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
112, 97,116,104, 32, 61, 32,115,111, 99,107,101,116, 46,116,114,121, 40,114,101,
113,116, 46,112, 97,116,104, 44, 32, 34,105,110,118, 97,108,105,100, 32,112, 97,
116,104, 32, 39,110,105,108, 39, 34, 41, 44, 10, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32,112, 97,114, 97,109,115, 32, 61, 32,114,101,113,116, 46,112, 97,114,
 97,109,115, 44, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,113,117,101,114,
121, 32, 61, 32,114,101,113,116, 46,113,117,101,114,121, 44, 10, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32,102,114, 97,103,109,101,110,116, 32, 61, 32,114,101,
113,116, 46,102,114, 97,103,109,101,110,116, 10, 32, 32, 32, 32, 32, 32, 32, 32,
125, 10, 32, 32, 32, 32,101,110,100, 10, 32, 32, 32, 32,114,101,116,117,114,110,
 32,117,114,108, 46, 98,117,105,108,100, 40,117, 41, 10,101,110,100, 10, 10,108,
111, 99, 97,108, 32,102,117,110, 99,116,105,111,110, 32, 97,100,106,117,115,116,
112,114,111,120,121, 40,114,101,113,116, 41, 10, 32, 32, 32, 32,108,111, 99, 97,
108, 32,112,114,111,120,121, 32, 61, 32,114,101,113,116, 46,112,114,111,120,121,
 32,111,114, 32, 80, 82, 79, 88, 89, 10, 32, 32, 32, 32,105,102, 32,112,114,111,
120,121, 32,116,104,101,110, 10, 32, 32, 32, 32, 32, 32, 32, 32,112,114,111,120,
121, 32, 61, 32,117,114,108, 46,112, 97,114,115,101, 40,112,114,111,120,121, 41,
 10, 32, 32, 32, 32, 32, 32, 32, 32,114,101,116,117,114,110, 32,112,114,111,120,
121, 46,104,111,115,116, 44, 32,112,114,111,120,121, 46,112,111,114,116, 32,111,
114, 32, 51, 49, 50, 56, 10, 32, 32, 32, 32,101,108,115,101, 10, 32, 32, 32, 32,
 32, 32, 32, 32,114,101,116,117,114,110, 32,114,101,113,116, 46,104,111,115,116,
 44, 32,114,101,113,116, 46,112,111,114,116, 10, 32, 32, 32, 32,101,110,100, 10,
101,110,100, 10, 10,108,111, 99, 97,108, 32,102,117,110, 99,116,105,111,110, 32,
 97,100,106,117,115,116,104,101, 97,100,101,114,115, 40,114,101,113,116, 41, 10,
 32, 32, 32, 32, 45, 45, 32,100,101,102, 97,117,108,116, 32,104,101, 97,100,101,
114,115, 10, 32, 32, 32, 32,108,111, 99, 97,108, 32,108,111,119,101,114, 32, 61,
 32,123, 10, 32, 32, 32, 32, 32, 32, 32, 32, 91, 34,117,115,101,114, 45, 97,103,
101,110,116, 34, 93, 32, 61, 32, 85, 83, 69, 82, 65, 71, 69, 78, 84, 44, 10, 32,
 32, 32, 32, 32, 32, 32, 32, 91, 34,104,111,115,116, 34, 93, 32, 61, 32,114,101,
113,116, 46,104,111,115,116, 44, 10, 32, 32, 32, 32, 32, 32, 32, 32, 91, 34, 99,
111,110,110,101, 99,116,105,111,110, 34, 93, 32, 61, 32, 34, 99,108,111,115,101,
 44, 32, 84, 69, 34, 44, 10, 32, 32, 32, 32, 32, 32, 32, 32, 91, 34,116,101, 34,
 93, 32, 61, 32, 34,116,114, 97,105,108,101,114,115, 34, 10, 32, 32, 32, 32,125,
 10, 32, 32, 32, 32, 45, 45, 32,105,102, 32,119,101, 32,104, 97,118,101, 32, 97,
117,116,104,101,110,116,105, 99, 97,116,105,111,110, 32,105,110,102,111,114,109,
 97,116,105,111,110, 44, 32,112, 97,115,115, 32,105,116, 32, 97,108,111,110,103,
 10, 32, 32, 32, 32,105,102, 32,114,101,113,116, 46,117,115,101,114, 32, 97,110,
100, 32,114,101,113,116, 46,112, 97,115,115,119,111,114,100, 32,116,104,101,110,
 10, 32, 32, 32, 32, 32, 32, 32, 32,108,111,119,101,114, 91, 34, 97,117,116,104,
111,114,105,122, 97,116,105,111,110, 34, 93, 32, 61, 32, 10, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 34, 66, 97,115,105, 99, 32, 34, 32, 46, 46, 32, 32,
 40,109,105,109,101, 46, 98, 54, 52, 40,114,101,113,116, 46,117,115,101,114, 32,
 46, 46, 32, 34, 58, 34, 32, 46, 46, 32,114,101,113,116, 46,112, 97,115,115,119,
111,114,100, 41, 41, 10, 32, 32, 32, 32,101,110,100, 10, 32, 32, 32, 32, 45, 45,
 32,111,118,101,114,114,105,100,101, 32,119,105,116,104, 32,117,115,101,114, 32,
104,101, 97,100,101,114,115, 10, 32, 32, 32, 32,102,111,114, 32,105, 44,118, 32,
105,110, 32, 98, 97,115,101, 46,112, 97,105,114,115, 40,114,101,113,116, 46,104,
101, 97,100,101,114,115, 32,111,114, 32,108,111,119,101,114, 41, 32,100,111, 10,
 32, 32, 32, 32, 32, 32, 32, 32,108,111,119,101,114, 91,115,116,114,105,110,103,
 46,108,111,119,101,114, 40,105, 41, 93, 32, 61, 32,118, 10, 32, 32, 32, 32,101,
110,100, 10, 32, 32, 32, 32,114,101,116,117,114,110, 32,108,111,119,101,114, 10,
101,110,100, 10, 10, 45, 45, 32,100,101,102, 97,117,108,116, 32,117,114,108, 32,
112, 97,114,116,115, 10,108,111, 99, 97,108, 32,100,101,102, 97,117,108,116, 32,
 61, 32,123, 10, 32, 32, 32, 32,104,111,115,116, 32, 61, 32, 34, 34, 44, 10, 32,
 32, 32, 32,112,111,114,116, 32, 61, 32, 80, 79, 82, 84, 44, 10, 32, 32, 32, 32,
112, 97,116,104, 32, 61, 34, 47, 34, 44, 10, 32, 32, 32, 32,115, 99,104,101,109,
101, 32, 61, 32, 34,104,116,116,112, 34, 10,125, 10, 10,108,111, 99, 97,108, 32,
102,117,110, 99,116,105,111,110, 32, 97,100,106,117,115,116,114,101,113,117,101,
115,116, 40,114,101,113,116, 41, 10, 32, 32, 32, 32, 45, 45, 32,112, 97,114,115,
101, 32,117,114,108, 32,105,102, 32,112,114,111,118,105,100,101,100, 10, 32, 32,
 32, 32,108,111, 99, 97,108, 32,110,114,101,113,116, 32, 61, 32,114,101,113,116,
 46,117,114,108, 32, 97,110,100, 32,117,114,108, 46,112, 97,114,115,101, 40,114,
101,113,116, 46,117,114,108, 44, 32,100,101,102, 97,117,108,116, 41, 32,111,114,
 32,123,125, 10, 32, 32, 32, 32, 45, 45, 32,101,120,112,108,105, 99,105,116, 32,
 99,111,109,112,111,110,101,110,116,115, 32,111,118,101,114,114,105,100,101, 32,
117,114,108, 10, 32, 32, 32, 32,102,111,114, 32,105, 44,118, 32,105,110, 32, 98,
 97,115,101, 46,112, 97,105,114,115, 40,114,101,113,116, 41, 32,100,111, 32,110,
114,101,113,116, 91,105, 93, 32, 61, 32,118, 32,101,110,100, 10, 32, 32, 32, 32,
105,102, 32,110,114,101,113,116, 46,112,111,114,116, 32, 61, 61, 32, 34, 34, 32,
116,104,101,110, 32,110,114,101,113,116, 46,112,111,114,116, 32, 61, 32, 56, 48,
 32,101,110,100, 10, 32, 32, 32, 32,115,111, 99,107,101,116, 46,116,114,121, 40,
110,114,101,113,116, 46,104,111,115,116, 32, 97,110,100, 32,110,114,101,113,116,
 46,104,111,115,116, 32,126, 61, 32, 34, 34, 44, 32, 10, 32, 32, 32, 32, 32, 32,
 32, 32, 34,105,110,118, 97,108,105,100, 32,104,111,115,116, 32, 39, 34, 32, 46,
 46, 32, 98, 97,115,101, 46,116,111,115,116,114,105,110,103, 40,110,114,101,113,
116, 46,104,111,115,116, 41, 32, 46, 46, 32, 34, 39, 34, 41, 10, 32, 32, 32, 32,
 45, 45, 32, 99,111,109,112,117,116,101, 32,117,114,105, 32,105,102, 32,117,115,
101,114, 32,104, 97,115,110, 39,116, 32,111,118,101,114,114,105,100,101,110, 10,
 32, 32, 32, 32,110,114,101,113,116, 46,117,114,105, 32, 61, 32,114,101,113,116,
 46,117,114,105, 32,111,114, 32, 97,100,106,117,115,116,117,114,105, 40,110,114,
101,113,116, 41, 10, 32, 32, 32, 32, 45, 45, 32, 97,106,117,115,116, 32,104,111,
115,116, 32, 97,110,100, 32,112,111,114,116, 32,105,102, 32,116,104,101,114,101,
 32,105,115, 32, 97, 32,112,114,111,120,121, 10, 32, 32, 32, 32,110,114,101,113,
116, 46,104,111,115,116, 44, 32,110,114,101,113,116, 46,112,111,114,116, 32, 61,
 32, 97,100,106,117,115,116,112,114,111,120,121, 40,110,114,101,113,116, 41, 10,
 32, 32, 32, 32, 45, 45, 32, 97,100,106,117,115,116, 32,104,101, 97,100,101,114,
115, 32,105,110, 32,114,101,113,117,101,115,116, 10, 32, 32, 32, 32,110,114,101,
113,116, 46,104,101, 97,100,101,114,115, 32, 61, 32, 97,100,106,117,115,116,104,
101, 97,100,101,114,115, 40,110,114,101,113,116, 41, 10, 32, 32, 32, 32,114,101,
116,117,114,110, 32,110,114,101,113,116, 10,101,110,100, 10, 10,108,111, 99, 97,
108, 32,102,117,110, 99,116,105,111,110, 32,115,104,111,117,108,100,114,101,100,
105,114,101, 99,116, 40,114,101,113,116, 44, 32, 99,111,100,101, 44, 32,104,101,
 97,100,101,114,115, 41, 10, 32, 32, 32, 32,114,101,116,117,114,110, 32,104,101,
 97,100,101,114,115, 46,108,111, 99, 97,116,105,111,110, 32, 97,110,100, 10, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,115,116,114,105,110,103, 46,103,115,117,
 98, 40,104,101, 97,100,101,114,115, 46,108,111, 99, 97,116,105,111,110, 44, 32,
 34, 37,115, 34, 44, 32, 34, 34, 41, 32,126, 61, 32, 34, 34, 32, 97,110,100, 10,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 40,114,101,113,116, 46,114,101,100,
105,114,101, 99,116, 32,126, 61, 32,102, 97,108,115,101, 41, 32, 97,110,100, 10,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 40, 99,111,100,101, 32, 61, 61, 32,
 51, 48, 49, 32,111,114, 32, 99,111,100,101, 32, 61, 61, 32, 51, 48, 50, 41, 32,
 97,110,100, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 40,110,111,116, 32,
114,101,113,116, 46,109,101,116,104,111,100, 32,111,114, 32,114,101,113,116, 46,
109,101,116,104,111,100, 32, 61, 61, 32, 34, 71, 69, 84, 34, 32,111,114, 32,114,
101,113,116, 46,109,101,116,104,111,100, 32, 61, 61, 32, 34, 72, 69, 65, 68, 34,
 41, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 97,110,100, 32, 40,110,111,
116, 32,114,101,113,116, 46,110,114,101,100,105,114,101, 99,116,115, 32,111,114,
 32,114,101,113,116, 46,110,114,101,100,105,114,101, 99,116,115, 32, 60, 32, 53,
 41, 10,101,110,100, 10, 10,108,111, 99, 97,108, 32,102,117,110, 99,116,105,111,
110, 32,115,104,111,117,108,100,114,101, 99,101,105,118,101, 98,111,100,121, 40,
114,101,113,116, 44, 32, 99,111,100,101, 41, 10, 32, 32, 32, 32,105,102, 32,114,
101,113,116, 46,109,101,116,104,111,100, 32, 61, 61, 32, 34, 72, 69, 65, 68, 34,
 32,116,104,101,110, 32,114,101,116,117,114,110, 32,110,105,108, 32,101,110,100,
 10, 32, 32, 32, 32,105,102, 32, 99,111,100,101, 32, 61, 61, 32, 50, 48, 52, 32,
111,114, 32, 99,111,100,101, 32, 61, 61, 32, 51, 48, 52, 32,116,104,101,110, 32,
114,101,116,117,114,110, 32,110,105,108, 32,101,110,100, 10, 32, 32, 32, 32,105,
102, 32, 99,111,100,101, 32, 62, 61, 32, 49, 48, 48, 32, 97,110,100, 32, 99,111,
100,101, 32, 60, 32, 50, 48, 48, 32,116,104,101,110, 32,114,101,116,117,114,110,
 32,110,105,108, 32,101,110,100, 10, 32, 32, 32, 32,114,101,116,117,114,110, 32,
 49, 10,101,110,100, 10, 10, 45, 45, 32,102,111,114,119, 97,114,100, 32,100,101,
 99,108, 97,114, 97,116,105,111,110,115, 10,108,111, 99, 97,108, 32,116,114,101,
113,117,101,115,116, 44, 32,116,114,101,100,105,114,101, 99,116, 10, 10,102,117,
110, 99,116,105,111,110, 32,116,114,101,100,105,114,101, 99,116, 40,114,101,113,
116, 44, 32,108,111, 99, 97,116,105,111,110, 41, 10, 32, 32, 32, 32,108,111, 99,
 97,108, 32,114,101,115,117,108,116, 44, 32, 99,111,100,101, 44, 32,104,101, 97,
100,101,114,115, 44, 32,115,116, 97,116,117,115, 32, 61, 32,116,114,101,113,117,
101,115,116, 32,123, 10, 32, 32, 32, 32, 32, 32, 32, 32, 45, 45, 32,116,104,101,
 32, 82, 70, 67, 32,115, 97,121,115, 32,116,104,101, 32,114,101,100,105,114,101,
 99,116, 32, 85, 82, 76, 32,104, 97,115, 32,116,111, 32, 98,101, 32, 97, 98,115,
111,108,117,116,101, 44, 32, 98,117,116, 32,115,111,109,101, 10, 32, 32, 32, 32,
 32, 32, 32, 32, 45, 45, 32,115,101,114,118,101,114,115, 32,100,111, 32,110,111,
116, 32,114,101,115,112,101, 99,116, 32,116,104, 97,116, 10, 32, 32, 32, 32, 32,
 32, 32, 32,117,114,108, 32, 61, 32,117,114,108, 46, 97, 98,115,111,108,117,116,
101, 40,114,101,113,116, 46,117,114,108, 44, 32,108,111, 99, 97,116,105,111,110,
 41, 44, 10, 32, 32, 32, 32, 32, 32, 32, 32,115,111,117,114, 99,101, 32, 61, 32,
114,101,113,116, 46,115,111,117,114, 99,101, 44, 10, 32, 32, 32, 32, 32, 32, 32,
 32,115,105,110,107, 32, 61, 32,114,101,113,116, 46,115,105,110,107, 44, 10, 32,
 32, 32, 32, 32, 32, 32, 32,104,101, 97,100,101,114,115, 32, 61, 32,114,101,113,
116, 46,104,101, 97,100,101,114,115, 44, 10, 32, 32, 32, 32, 32, 32, 32, 32,112,
114,111,120,121, 32, 61, 32,114,101,113,116, 46,112,114,111,120,121, 44, 32, 10,
 32, 32, 32, 32, 32, 32, 32, 32,110,114,101,100,105,114,101, 99,116,115, 32, 61,
 32, 40,114,101,113,116, 46,110,114,101,100,105,114,101, 99,116,115, 32,111,114,
 32, 48, 41, 32, 43, 32, 49, 44, 10, 32, 32, 32, 32, 32, 32, 32, 32, 99,114,101,
 97,116,101, 32, 61, 32,114,101,113,116, 46, 99,114,101, 97,116,101, 10, 32, 32,
 32, 32,125, 32, 32, 32, 10, 32, 32, 32, 32, 45, 45, 32,112, 97,115,115, 32,108,
111, 99, 97,116,105,111,110, 32,104,101, 97,100,101,114, 32, 98, 97, 99,107, 32,
 97,115, 32, 97, 32,104,105,110,116, 32,119,101, 32,114,101,100,105,114,101, 99,
116,101,100, 10, 32, 32, 32, 32,104,101, 97,100,101,114,115, 32, 61, 32,104,101,
 97,100,101,114,115, 32,111,114, 32,123,125, 10, 32, 32, 32, 32,104,101, 97,100,
101,114,115, 46,108,111, 99, 97,116,105,111,110, 32, 61, 32,104,101, 97,100,101,
114,115, 46,108,111, 99, 97,116,105,111,110, 32,111,114, 32,108,111, 99, 97,116,
105,111,110, 10, 32, 32, 32, 32,114,101,116,117,114,110, 32,114,101,115,117,108,
116, 44, 32, 99,111,100,101, 44, 32,104,101, 97,100,101,114,115, 44, 32,115,116,
 97,116,117,115, 10,101,110,100, 10, 10,102,117,110, 99,116,105,111,110, 32,116,
114,101,113,117,101,115,116, 40,114,101,113,116, 41, 10, 32, 32, 32, 32, 45, 45,
 32,119,101, 32,108,111,111,112, 32,117,110,116,105,108, 32,119,101, 32,103,101,
116, 32,119,104, 97,116, 32,119,101, 32,119, 97,110,116, 44, 32,111,114, 10, 32,
 32, 32, 32, 45, 45, 32,117,110,116,105,108, 32,119,101, 32, 97,114,101, 32,115,
117,114,101, 32,116,104,101,114,101, 32,105,115, 32,110,111, 32,119, 97,121, 32,
116,111, 32,103,101,116, 32,105,116, 10, 32, 32, 32, 32,108,111, 99, 97,108, 32,
110,114,101,113,116, 32, 61, 32, 97,100,106,117,115,116,114,101,113,117,101,115,
116, 40,114,101,113,116, 41, 10, 32, 32, 32, 32,108,111, 99, 97,108, 32,104, 32,
 61, 32,111,112,101,110, 40,110,114,101,113,116, 46,104,111,115,116, 44, 32,110,
114,101,113,116, 46,112,111,114,116, 44, 32,110,114,101,113,116, 46, 99,114,101,
 97,116,101, 41, 10, 32, 32, 32, 32, 45, 45, 32,115,101,110,100, 32,114,101,113,
117,101,115,116, 32,108,105,110,101, 32, 97,110,100, 32,104,101, 97,100,101,114,
115, 10, 32, 32, 32, 32,104, 58,115,101,110,100,114,101,113,117,101,115,116,108,
105,110,101, 40,110,114,101,113,116, 46,109,101,116,104,111,100, 44, 32,110,114,
101,113,116, 46,117,114,105, 41, 10, 32, 32, 32, 32,104, 58,115,101,110,100,104,
101, 97,100,101,114,115, 40,110,114,101,113,116, 46,104,101, 97,100,101,114,115,
 41, 10, 32, 32, 32, 32, 45, 45, 32,105,102, 32,116,104,101,114,101, 32,105,115,
 32, 97, 32, 98,111,100,121, 44, 32,115,101,110,100, 32,105,116, 10, 32, 32, 32,
 32,105,102, 32,110,114,101,113,116, 46,115,111,117,114, 99,101, 32,116,104,101,
110, 10, 32, 32, 32, 32, 32, 32, 32, 32,104, 58,115,101,110,100, 98,111,100,121,
 40,110,114,101,113,116, 46,104,101, 97,100,101,114,115, 44, 32,110,114,101,113,
116, 46,115,111,117,114, 99,101, 44, 32,110,114,101,113,116, 46,115,116,101,112,
 41, 32, 10, 32, 32, 32, 32,101,110,100, 10, 32, 32, 32, 32,108,111, 99, 97,108,
 32, 99,111,100,101, 44, 32,115,116, 97,116,117,115, 32, 61, 32,104, 58,114,101,
 99,101,105,118,101,115,116, 97,116,117,115,108,105,110,101, 40, 41, 10, 32, 32,
 32, 32, 45, 45, 32,105,102, 32,105,116, 32,105,115, 32, 97,110, 32, 72, 84, 84,
 80, 47, 48, 46, 57, 32,115,101,114,118,101,114, 44, 32,115,105,109,112,108,121,
 32,103,101,116, 32,116,104,101, 32, 98,111,100,121, 32, 97,110,100, 32,119,101,
 32, 97,114,101, 32,100,111,110,101, 10, 32, 32, 32, 32,105,102, 32,110,111,116,
 32, 99,111,100,101, 32,116,104,101,110, 10, 32, 32, 32, 32, 32, 32, 32, 32,104,
 58,114,101, 99,101,105,118,101, 48, 57, 98,111,100,121, 40,115,116, 97,116,117,
115, 44, 32,110,114,101,113,116, 46,115,105,110,107, 44, 32,110,114,101,113,116,
 46,115,116,101,112, 41, 10, 32, 32, 32, 32, 32, 32, 32, 32,114,101,116,117,114,
110, 32, 49, 44, 32, 50, 48, 48, 10, 32, 32, 32, 32,101,110,100, 10, 32, 32, 32,
 32,108,111, 99, 97,108, 32,104,101, 97,100,101,114,115, 10, 32, 32, 32, 32, 45,
 45, 32,105,103,110,111,114,101, 32, 97,110,121, 32, 49, 48, 48, 45, 99,111,110,
116,105,110,117,101, 32,109,101,115,115, 97,103,101,115, 10, 32, 32, 32, 32,119,
104,105,108,101, 32, 99,111,100,101, 32, 61, 61, 32, 49, 48, 48, 32,100,111, 32,
 10, 32, 32, 32, 32, 32, 32, 32, 32,104,101, 97,100,101,114,115, 32, 61, 32,104,
 58,114,101, 99,101,105,118,101,104,101, 97,100,101,114,115, 40, 41, 10, 32, 32,
 32, 32, 32, 32, 32, 32, 99,111,100,101, 44, 32,115,116, 97,116,117,115, 32, 61,
 32,104, 58,114,101, 99,101,105,118,101,115,116, 97,116,117,115,108,105,110,101,
 40, 41, 10, 32, 32, 32, 32,101,110,100, 10, 32, 32, 32, 32,104,101, 97,100,101,
114,115, 32, 61, 32,104, 58,114,101, 99,101,105,118,101,104,101, 97,100,101,114,
115, 40, 41, 10, 32, 32, 32, 32, 45, 45, 32, 97,116, 32,116,104,105,115, 32,112,
111,105,110,116, 32,119,101, 32,115,104,111,117,108,100, 32,104, 97,118,101, 32,
 97, 32,104,111,110,101,115,116, 32,114,101,112,108,121, 32,102,114,111,109, 32,
116,104,101, 32,115,101,114,118,101,114, 10, 32, 32, 32, 32, 45, 45, 32,119,101,
 32, 99, 97,110, 39,116, 32,114,101,100,105,114,101, 99,116, 32,105,102, 32,119,
101, 32, 97,108,114,101, 97,100,121, 32,117,115,101,100, 32,116,104,101, 32,115,
111,117,114, 99,101, 44, 32,115,111, 32,119,101, 32,114,101,112,111,114,116, 32,
116,104,101, 32,101,114,114,111,114, 32, 10, 32, 32, 32, 32,105,102, 32,115,104,
111,117,108,100,114,101,100,105,114,101, 99,116, 40,110,114,101,113,116, 44, 32,
 99,111,100,101, 44, 32,104,101, 97,100,101,114,115, 41, 32, 97,110,100, 32,110,
111,116, 32,110,114,101,113,116, 46,115,111,117,114, 99,101, 32,116,104,101,110,
 10, 32, 32, 32, 32, 32, 32, 32, 32,104, 58, 99,108,111,115,101, 40, 41, 10, 32,
 32, 32, 32, 32, 32, 32, 32,114,101,116,117,114,110, 32,116,114,101,100,105,114,
101, 99,116, 40,114,101,113,116, 44, 32,104,101, 97,100,101,114,115, 46,108,111,
 99, 97,116,105,111,110, 41, 10, 32, 32, 32, 32,101,110,100, 10, 32, 32, 32, 32,
 45, 45, 32,104,101,114,101, 32,119,101, 32, 97,114,101, 32,102,105,110, 97,108,
108,121, 32,100,111,110,101, 10, 32, 32, 32, 32,105,102, 32,115,104,111,117,108,
100,114,101, 99,101,105,118,101, 98,111,100,121, 40,110,114,101,113,116, 44, 32,
 99,111,100,101, 41, 32,116,104,101,110, 10, 32, 32, 32, 32, 32, 32, 32, 32,104,
 58,114,101, 99,101,105,118,101, 98,111,100,121, 40,104,101, 97,100,101,114,115,
 44, 32,110,114,101,113,116, 46,115,105,110,107, 44, 32,110,114,101,113,116, 46,
115,116,101,112, 41, 10, 32, 32, 32, 32,101,110,100, 10, 32, 32, 32, 32,104, 58,
 99,108,111,115,101, 40, 41, 10, 32, 32, 32, 32,114,101,116,117,114,110, 32, 49,
 44, 32, 99,111,100,101, 44, 32,104,101, 97,100,101,114,115, 44, 32,115,116, 97,
116,117,115, 10,101,110,100, 10, 10,108,111, 99, 97,108, 32,102,117,110, 99,116,
105,111,110, 32,115,114,101,113,117,101,115,116, 40,117, 44, 32, 98, 41, 10, 32,
 32, 32, 32,108,111, 99, 97,108, 32,116, 32, 61, 32,123,125, 10, 32, 32, 32, 32,
108,111, 99, 97,108, 32,114,101,113,116, 32, 61, 32,123, 10, 32, 32, 32, 32, 32,
 32, 32, 32,117,114,108, 32, 61, 32,117, 44, 10, 32, 32, 32, 32, 32, 32, 32, 32,
115,105,110,107, 32, 61, 32,108,116,110, 49, 50, 46,115,105,110,107, 46,116, 97,
 98,108,101, 40,116, 41, 10, 32, 32, 32, 32,125, 10, 32, 32, 32, 32,105,102, 32,
 98, 32,116,104,101,110, 10, 32, 32, 32, 32, 32, 32, 32, 32,114,101,113,116, 46,
115,111,117,114, 99,101, 32, 61, 32,108,116,110, 49, 50, 46,115,111,117,114, 99,
101, 46,115,116,114,105,110,103, 40, 98, 41, 10, 32, 32, 32, 32, 32, 32, 32, 32,
114,101,113,116, 46,104,101, 97,100,101,114,115, 32, 61, 32,123, 10, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 91, 34, 99,111,110,116,101,110,116, 45,108,
101,110,103,116,104, 34, 93, 32, 61, 32,115,116,114,105,110,103, 46,108,101,110,
 40, 98, 41, 44, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 91, 34, 99,
111,110,116,101,110,116, 45,116,121,112,101, 34, 93, 32, 61, 32, 34, 97,112,112,
108,105, 99, 97,116,105,111,110, 47,120, 45,119,119,119, 45,102,111,114,109, 45,
117,114,108,101,110, 99,111,100,101,100, 34, 10, 32, 32, 32, 32, 32, 32, 32, 32,
125, 10, 32, 32, 32, 32, 32, 32, 32, 32,114,101,113,116, 46,109,101,116,104,111,
100, 32, 61, 32, 34, 80, 79, 83, 84, 34, 10, 32, 32, 32, 32,101,110,100, 10, 32,
 32, 32, 32,108,111, 99, 97,108, 32, 99,111,100,101, 44, 32,104,101, 97,100,101,
114,115, 44, 32,115,116, 97,116,117,115, 32, 61, 32,115,111, 99,107,101,116, 46,
115,107,105,112, 40, 49, 44, 32,116,114,101,113,117,101,115,116, 40,114,101,113,
116, 41, 41, 10, 32, 32, 32, 32,114,101,116,117,114,110, 32,116, 97, 98,108,101,
 46, 99,111,110, 99, 97,116, 40,116, 41, 44, 32, 99,111,100,101, 44, 32,104,101,
 97,100,101,114,115, 44, 32,115,116, 97,116,117,115, 10,101,110,100, 10, 10,114,
101,113,117,101,115,116, 32, 61, 32,115,111, 99,107,101,116, 46,112,114,111,116,
101, 99,116, 40,102,117,110, 99,116,105,111,110, 40,114,101,113,116, 44, 32, 98,
111,100,121, 41, 10, 32, 32, 32, 32,105,102, 32, 98, 97,115,101, 46,116,121,112,
101, 40,114,101,113,116, 41, 32, 61, 61, 32, 34,115,116,114,105,110,103, 34, 32,
116,104,101,110, 32,114,101,116,117,114,110, 32,115,114,101,113,117,101,115,116,
 40,114,101,113,116, 44, 32, 98,111,100,121, 41, 10, 32, 32, 32, 32,101,108,115,
101, 32,114,101,116,117,114,110, 32,116,114,101,113,117,101,115,116, 40,114,101,
113,116, 41, 32,101,110,100, 10,101,110,100, 41, 10,
};

 if (luaL_loadbuffer(L,(const char*)B1,sizeof(B1),"http.lua")==0) lua_call(L, 0, 0);
}
