/* code automatically generated by bin2c -- DO NOT EDIT */
{
/* #include'ing this file in a C program is equivalent to calling
  if (luaL_loadfile(L,"mime.lua")==0) lua_call(L, 0, 0); 
*/
/* mime.lua */
static const unsigned char B1[]={
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10, 45, 45,
 32, 77, 73, 77, 69, 32,115,117,112,112,111,114,116, 32,102,111,114, 32,116,104,
101, 32, 76,117, 97, 32,108, 97,110,103,117, 97,103,101, 46, 10, 45, 45, 32, 65,
117,116,104,111,114, 58, 32, 68,105,101,103,111, 32, 78,101,104, 97, 98, 10, 45,
 45, 32, 67,111,110,102,111,114,109,105,110,103, 32,116,111, 32, 82, 70, 67,115,
 32, 50, 48, 52, 53, 45, 50, 48, 52, 57, 10, 45, 45, 32, 82, 67, 83, 32, 73, 68,
 58, 32, 36, 73,100, 58, 32,109,105,109,101, 46,108,117, 97, 44,118, 32, 49, 46,
 50, 57, 32, 50, 48, 48, 55, 47, 48, 54, 47, 49, 49, 32, 50, 51, 58, 52, 52, 58,
 53, 52, 32,100,105,101,103,111, 32, 69,120,112, 32, 36, 10, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10, 10, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10, 45, 45, 32, 68,101, 99,108, 97,
114,101, 32,109,111,100,117,108,101, 32, 97,110,100, 32,105,109,112,111,114,116,
 32,100,101,112,101,110,100,101,110, 99,105,101,115, 10, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10,108,111, 99, 97,108, 32, 98, 97,
115,101, 32, 61, 32, 95, 71, 10,108,111, 99, 97,108, 32,108,116,110, 49, 50, 32,
 61, 32,114,101,113,117,105,114,101, 40, 34,108,116,110, 49, 50, 34, 41, 10,108,
111, 99, 97,108, 32,109,105,109,101, 32, 61, 32,114,101,113,117,105,114,101, 40,
 34,109,105,109,101, 46, 99,111,114,101, 34, 41, 10,108,111, 99, 97,108, 32,105,
111, 32, 61, 32,114,101,113,117,105,114,101, 40, 34,105,111, 34, 41, 10,108,111,
 99, 97,108, 32,115,116,114,105,110,103, 32, 61, 32,114,101,113,117,105,114,101,
 40, 34,115,116,114,105,110,103, 34, 41, 10,109,111,100,117,108,101, 40, 34,109,
105,109,101, 34, 41, 10, 10, 45, 45, 32,101,110, 99,111,100,101, 44, 32,100,101,
 99,111,100,101, 32, 97,110,100, 32,119,114, 97,112, 32, 97,108,103,111,114,105,
116,104,109, 32,116, 97, 98,108,101,115, 10,101,110, 99,111,100,101,116, 32, 61,
 32,123,125, 10,100,101, 99,111,100,101,116, 32, 61, 32,123,125, 10,119,114, 97,
112,116, 32, 61, 32,123,125, 10, 10, 45, 45, 32, 99,114,101, 97,116,101,115, 32,
 97, 32,102,117,110, 99,116,105,111,110, 32,116,104, 97,116, 32, 99,104,111,111,
115,101,115, 32, 97, 32,102,105,108,116,101,114, 32, 98,121, 32,110, 97,109,101,
 32,102,114,111,109, 32, 97, 32,103,105,118,101,110, 32,116, 97, 98,108,101, 10,
108,111, 99, 97,108, 32,102,117,110, 99,116,105,111,110, 32, 99,104,111,111,115,
101, 40,116, 97, 98,108,101, 41, 10, 32, 32, 32, 32,114,101,116,117,114,110, 32,
102,117,110, 99,116,105,111,110, 40,110, 97,109,101, 44, 32,111,112,116, 49, 44,
 32,111,112,116, 50, 41, 10, 32, 32, 32, 32, 32, 32, 32, 32,105,102, 32, 98, 97,
115,101, 46,116,121,112,101, 40,110, 97,109,101, 41, 32,126, 61, 32, 34,115,116,
114,105,110,103, 34, 32,116,104,101,110, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32,110, 97,109,101, 44, 32,111,112,116, 49, 44, 32,111,112,116, 50, 32,
 61, 32, 34,100,101,102, 97,117,108,116, 34, 44, 32,110, 97,109,101, 44, 32,111,
112,116, 49, 10, 32, 32, 32, 32, 32, 32, 32, 32,101,110,100, 10, 32, 32, 32, 32,
 32, 32, 32, 32,108,111, 99, 97,108, 32,102, 32, 61, 32,116, 97, 98,108,101, 91,
110, 97,109,101, 32,111,114, 32, 34,110,105,108, 34, 93, 10, 32, 32, 32, 32, 32,
 32, 32, 32,105,102, 32,110,111,116, 32,102, 32,116,104,101,110, 32, 10, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 98, 97,115,101, 46,101,114,114,111,114,
 40, 34,117,110,107,110,111,119,110, 32,107,101,121, 32, 40, 34, 32, 46, 46, 32,
 98, 97,115,101, 46,116,111,115,116,114,105,110,103, 40,110, 97,109,101, 41, 32,
 46, 46, 32, 34, 41, 34, 44, 32, 51, 41, 10, 32, 32, 32, 32, 32, 32, 32, 32,101,
108,115,101, 32,114,101,116,117,114,110, 32,102, 40,111,112,116, 49, 44, 32,111,
112,116, 50, 41, 32,101,110,100, 10, 32, 32, 32, 32,101,110,100, 10,101,110,100,
 10, 10, 45, 45, 32,100,101,102,105,110,101, 32,116,104,101, 32,101,110, 99,111,
100,105,110,103, 32,102,105,108,116,101,114,115, 10,101,110, 99,111,100,101,116,
 91, 39, 98, 97,115,101, 54, 52, 39, 93, 32, 61, 32,102,117,110, 99,116,105,111,
110, 40, 41, 10, 32, 32, 32, 32,114,101,116,117,114,110, 32,108,116,110, 49, 50,
 46,102,105,108,116,101,114, 46, 99,121, 99,108,101, 40, 98, 54, 52, 44, 32, 34,
 34, 41, 10,101,110,100, 10, 10,101,110, 99,111,100,101,116, 91, 39,113,117,111,
116,101,100, 45,112,114,105,110,116, 97, 98,108,101, 39, 93, 32, 61, 32,102,117,
110, 99,116,105,111,110, 40,109,111,100,101, 41, 10, 32, 32, 32, 32,114,101,116,
117,114,110, 32,108,116,110, 49, 50, 46,102,105,108,116,101,114, 46, 99,121, 99,
108,101, 40,113,112, 44, 32, 34, 34, 44, 10, 32, 32, 32, 32, 32, 32, 32, 32, 40,
109,111,100,101, 32, 61, 61, 32, 34, 98,105,110, 97,114,121, 34, 41, 32, 97,110,
100, 32, 34, 61, 48, 68, 61, 48, 65, 34, 32,111,114, 32, 34, 92,114, 92,110, 34,
 41, 10,101,110,100, 10, 10, 45, 45, 32,100,101,102,105,110,101, 32,116,104,101,
 32,100,101, 99,111,100,105,110,103, 32,102,105,108,116,101,114,115, 10,100,101,
 99,111,100,101,116, 91, 39, 98, 97,115,101, 54, 52, 39, 93, 32, 61, 32,102,117,
110, 99,116,105,111,110, 40, 41, 10, 32, 32, 32, 32,114,101,116,117,114,110, 32,
108,116,110, 49, 50, 46,102,105,108,116,101,114, 46, 99,121, 99,108,101, 40,117,
110, 98, 54, 52, 44, 32, 34, 34, 41, 10,101,110,100, 10, 10,100,101, 99,111,100,
101,116, 91, 39,113,117,111,116,101,100, 45,112,114,105,110,116, 97, 98,108,101,
 39, 93, 32, 61, 32,102,117,110, 99,116,105,111,110, 40, 41, 10, 32, 32, 32, 32,
114,101,116,117,114,110, 32,108,116,110, 49, 50, 46,102,105,108,116,101,114, 46,
 99,121, 99,108,101, 40,117,110,113,112, 44, 32, 34, 34, 41, 10,101,110,100, 10,
 10,108,111, 99, 97,108, 32,102,117,110, 99,116,105,111,110, 32,102,111,114,109,
 97,116, 40, 99,104,117,110,107, 41, 10, 32, 32, 32, 32,105,102, 32, 99,104,117,
110,107, 32,116,104,101,110, 10, 32, 32, 32, 32, 32, 32, 32, 32,105,102, 32, 99,
104,117,110,107, 32, 61, 61, 32, 34, 34, 32,116,104,101,110, 32,114,101,116,117,
114,110, 32, 34, 39, 39, 34, 10, 32, 32, 32, 32, 32, 32, 32, 32,101,108,115,101,
 32,114,101,116,117,114,110, 32,115,116,114,105,110,103, 46,108,101,110, 40, 99,
104,117,110,107, 41, 32,101,110,100, 10, 32, 32, 32, 32,101,108,115,101, 32,114,
101,116,117,114,110, 32, 34,110,105,108, 34, 32,101,110,100, 10,101,110,100, 10,
 10, 45, 45, 32,100,101,102,105,110,101, 32,116,104,101, 32,108,105,110,101, 45,
119,114, 97,112, 32,102,105,108,116,101,114,115, 10,119,114, 97,112,116, 91, 39,
116,101,120,116, 39, 93, 32, 61, 32,102,117,110, 99,116,105,111,110, 40,108,101,
110,103,116,104, 41, 10, 32, 32, 32, 32,108,101,110,103,116,104, 32, 61, 32,108,
101,110,103,116,104, 32,111,114, 32, 55, 54, 10, 32, 32, 32, 32,114,101,116,117,
114,110, 32,108,116,110, 49, 50, 46,102,105,108,116,101,114, 46, 99,121, 99,108,
101, 40,119,114,112, 44, 32,108,101,110,103,116,104, 44, 32,108,101,110,103,116,
104, 41, 10,101,110,100, 10,119,114, 97,112,116, 91, 39, 98, 97,115,101, 54, 52,
 39, 93, 32, 61, 32,119,114, 97,112,116, 91, 39,116,101,120,116, 39, 93, 10,119,
114, 97,112,116, 91, 39,100,101,102, 97,117,108,116, 39, 93, 32, 61, 32,119,114,
 97,112,116, 91, 39,116,101,120,116, 39, 93, 10, 10,119,114, 97,112,116, 91, 39,
113,117,111,116,101,100, 45,112,114,105,110,116, 97, 98,108,101, 39, 93, 32, 61,
 32,102,117,110, 99,116,105,111,110, 40, 41, 10, 32, 32, 32, 32,114,101,116,117,
114,110, 32,108,116,110, 49, 50, 46,102,105,108,116,101,114, 46, 99,121, 99,108,
101, 40,113,112,119,114,112, 44, 32, 55, 54, 44, 32, 55, 54, 41, 10,101,110,100,
 10, 10, 45, 45, 32,102,117,110, 99,116,105,111,110, 32,116,104, 97,116, 32, 99,
104,111,111,115,101, 32,116,104,101, 32,101,110, 99,111,100,105,110,103, 44, 32,
100,101, 99,111,100,105,110,103, 32,111,114, 32,119,114, 97,112, 32, 97,108,103,
111,114,105,116,104,109, 10,101,110, 99,111,100,101, 32, 61, 32, 99,104,111,111,
115,101, 40,101,110, 99,111,100,101,116, 41, 10,100,101, 99,111,100,101, 32, 61,
 32, 99,104,111,111,115,101, 40,100,101, 99,111,100,101,116, 41, 10,119,114, 97,
112, 32, 61, 32, 99,104,111,111,115,101, 40,119,114, 97,112,116, 41, 10, 10, 45,
 45, 32,100,101,102,105,110,101, 32,116,104,101, 32,101,110,100, 45,111,102, 45,
108,105,110,101, 32,110,111,114,109, 97,108,105,122, 97,116,105,111,110, 32,102,
105,108,116,101,114, 10,102,117,110, 99,116,105,111,110, 32,110,111,114,109, 97,
108,105,122,101, 40,109, 97,114,107,101,114, 41, 10, 32, 32, 32, 32,114,101,116,
117,114,110, 32,108,116,110, 49, 50, 46,102,105,108,116,101,114, 46, 99,121, 99,
108,101, 40,101,111,108, 44, 32, 48, 44, 32,109, 97,114,107,101,114, 41, 10,101,
110,100, 10, 10, 45, 45, 32,104,105,103,104, 32,108,101,118,101,108, 32,115,116,
117,102,102,105,110,103, 32,102,105,108,116,101,114, 10,102,117,110, 99,116,105,
111,110, 32,115,116,117,102,102, 40, 41, 10, 32, 32, 32, 32,114,101,116,117,114,
110, 32,108,116,110, 49, 50, 46,102,105,108,116,101,114, 46, 99,121, 99,108,101,
 40,100,111,116, 44, 32, 50, 41, 10,101,110,100, 10,
};

 if (luaL_loadbuffer(L,(const char*)B1,sizeof(B1),"mime.lua")==0) lua_call(L, 0, 0);
}
