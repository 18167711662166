/* code automatically generated by bin2c -- DO NOT EDIT */
{
/* #include'ing this file in a C program is equivalent to calling
  if (luaL_loadfile(L,"ltn12.lua")==0) lua_call(L, 0, 0); 
*/
/* ltn12.lua */
static const unsigned char B1[]={
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10, 45, 45,
 32, 76, 84, 78, 49, 50, 32, 45, 32, 70,105,108,116,101,114,115, 44, 32,115,111,
117,114, 99,101,115, 44, 32,115,105,110,107,115, 32, 97,110,100, 32,112,117,109,
112,115, 46, 10, 45, 45, 32, 76,117, 97, 83,111, 99,107,101,116, 32,116,111,111,
108,107,105,116, 46, 10, 45, 45, 32, 65,117,116,104,111,114, 58, 32, 68,105,101,
103,111, 32, 78,101,104, 97, 98, 10, 45, 45, 32, 82, 67, 83, 32, 73, 68, 58, 32,
 36, 73,100, 58, 32,108,116,110, 49, 50, 46,108,117, 97, 44,118, 32, 49, 46, 51,
 49, 32, 50, 48, 48, 54, 47, 48, 52, 47, 48, 51, 32, 48, 52, 58, 52, 53, 58, 52,
 50, 32,100,105,101,103,111, 32, 69,120,112, 32, 36, 10, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10, 10, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10, 45, 45, 32, 68,101, 99,108, 97,114,
101, 32,109,111,100,117,108,101, 10, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 10,108,111, 99, 97,108, 32,115,116,114,105,110,103, 32,
 61, 32,114,101,113,117,105,114,101, 40, 34,115,116,114,105,110,103, 34, 41, 10,
108,111, 99, 97,108, 32,116, 97, 98,108,101, 32, 61, 32,114,101,113,117,105,114,
101, 40, 34,116, 97, 98,108,101, 34, 41, 10,108,111, 99, 97,108, 32, 98, 97,115,
101, 32, 61, 32, 95, 71, 10,109,111,100,117,108,101, 40, 34,108,116,110, 49, 50,
 34, 41, 10, 10,102,105,108,116,101,114, 32, 61, 32,123,125, 10,115,111,117,114,
 99,101, 32, 61, 32,123,125, 10,115,105,110,107, 32, 61, 32,123,125, 10,112,117,
109,112, 32, 61, 32,123,125, 10, 10, 45, 45, 32, 50, 48, 52, 56, 32,115,101,101,
109,115, 32,116,111, 32, 98,101, 32, 98,101,116,116,101,114, 32,105,110, 32,119,
105,110,100,111,119,115, 46, 46, 46, 10, 66, 76, 79, 67, 75, 83, 73, 90, 69, 32,
 61, 32, 50, 48, 52, 56, 10, 95, 86, 69, 82, 83, 73, 79, 78, 32, 61, 32, 34, 76,
 84, 78, 49, 50, 32, 49, 46, 48, 46, 49, 34, 10, 10, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10, 45, 45, 32, 70,105,108,116,101,114,
 32,115,116,117,102,102, 10, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 10, 45, 45, 32,114,101,116,117,114,110,115, 32, 97, 32,104,105,
103,104, 32,108,101,118,101,108, 32,102,105,108,116,101,114, 32,116,104, 97,116,
 32, 99,121, 99,108,101,115, 32, 97, 32,108,111,119, 45,108,101,118,101,108, 32,
102,105,108,116,101,114, 10,102,117,110, 99,116,105,111,110, 32,102,105,108,116,
101,114, 46, 99,121, 99,108,101, 40,108,111,119, 44, 32, 99,116,120, 44, 32,101,
120,116,114, 97, 41, 10, 32, 32, 32, 32, 98, 97,115,101, 46, 97,115,115,101,114,
116, 40,108,111,119, 41, 10, 32, 32, 32, 32,114,101,116,117,114,110, 32,102,117,
110, 99,116,105,111,110, 40, 99,104,117,110,107, 41, 10, 32, 32, 32, 32, 32, 32,
 32, 32,108,111, 99, 97,108, 32,114,101,116, 10, 32, 32, 32, 32, 32, 32, 32, 32,
114,101,116, 44, 32, 99,116,120, 32, 61, 32,108,111,119, 40, 99,116,120, 44, 32,
 99,104,117,110,107, 44, 32,101,120,116,114, 97, 41, 10, 32, 32, 32, 32, 32, 32,
 32, 32,114,101,116,117,114,110, 32,114,101,116, 10, 32, 32, 32, 32,101,110,100,
 10,101,110,100, 10, 10, 45, 45, 32, 99,104, 97,105,110,115, 32, 97, 32, 98,117,
110, 99,104, 32,111,102, 32,102,105,108,116,101,114,115, 32,116,111,103,101,116,
104,101,114, 10, 45, 45, 32, 40,116,104, 97,110,107,115, 32,116,111, 32, 87,105,
109, 32, 67,111,117,119,101,110, 98,101,114,103, 41, 10,102,117,110, 99,116,105,
111,110, 32,102,105,108,116,101,114, 46, 99,104, 97,105,110, 40, 46, 46, 46, 41,
 10, 32, 32, 32, 32,108,111, 99, 97,108, 32,110, 32, 61, 32,116, 97, 98,108,101,
 46,103,101,116,110, 40, 97,114,103, 41, 10, 32, 32, 32, 32,108,111, 99, 97,108,
 32,116,111,112, 44, 32,105,110,100,101,120, 32, 61, 32, 49, 44, 32, 49, 10, 32,
 32, 32, 32,108,111, 99, 97,108, 32,114,101,116,114,121, 32, 61, 32, 34, 34, 10,
 32, 32, 32, 32,114,101,116,117,114,110, 32,102,117,110, 99,116,105,111,110, 40,
 99,104,117,110,107, 41, 10, 32, 32, 32, 32, 32, 32, 32, 32,114,101,116,114,121,
 32, 61, 32, 99,104,117,110,107, 32, 97,110,100, 32,114,101,116,114,121, 10, 32,
 32, 32, 32, 32, 32, 32, 32,119,104,105,108,101, 32,116,114,117,101, 32,100,111,
 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,105,102, 32,105,110,100,101,
120, 32, 61, 61, 32,116,111,112, 32,116,104,101,110, 10, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 99,104,117,110,107, 32, 61, 32, 97,114,
103, 91,105,110,100,101,120, 93, 40, 99,104,117,110,107, 41, 10, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,105,102, 32, 99,104,117,110,107,
 32, 61, 61, 32, 34, 34, 32,111,114, 32,116,111,112, 32, 61, 61, 32,110, 32,116,
104,101,110, 32,114,101,116,117,114,110, 32, 99,104,117,110,107, 10, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,101,108,115,101,105,102, 32,
 99,104,117,110,107, 32,116,104,101,110, 32,105,110,100,101,120, 32, 61, 32,105,
110,100,101,120, 32, 43, 32, 49, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32,101,108,115,101, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,116,111,112, 32, 61, 32,116,111,112, 43,
 49, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32,105,110,100,101,120, 32, 61, 32,116,111,112, 10, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,101,110,100, 10, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32,101,108,115,101, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 99,104,117,110,107, 32, 61, 32, 97,114,103, 91,105,
110,100,101,120, 93, 40, 99,104,117,110,107, 32,111,114, 32, 34, 34, 41, 10, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,105,102, 32, 99,104,
117,110,107, 32, 61, 61, 32, 34, 34, 32,116,104,101,110, 10, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,105,110,100,101,120,
 32, 61, 32,105,110,100,101,120, 32, 45, 32, 49, 10, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 99,104,117,110,107, 32, 61,
 32,114,101,116,114,121, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32,101,108,115,101,105,102, 32, 99,104,117,110,107, 32,116,104,101,110,
 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32,105,102, 32,105,110,100,101,120, 32, 61, 61, 32,110, 32,116,104,101,110, 32,
114,101,116,117,114,110, 32, 99,104,117,110,107, 10, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,101,108,115,101, 32,105,110,
100,101,120, 32, 61, 32,105,110,100,101,120, 32, 43, 32, 49, 32,101,110,100, 10,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,101,108,115,101,
 32, 98, 97,115,101, 46,101,114,114,111,114, 40, 34,102,105,108,116,101,114, 32,
114,101,116,117,114,110,101,100, 32,105,110, 97,112,112,114,111,112,114,105, 97,
116,101, 32,110,105,108, 34, 41, 32,101,110,100, 10, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32,101,110,100, 10, 32, 32, 32, 32, 32, 32, 32, 32,101,110,100,
 10, 32, 32, 32, 32,101,110,100, 10,101,110,100, 10, 10, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10, 45, 45, 32, 83,111,117,114, 99,
101, 32,115,116,117,102,102, 10, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 10, 45, 45, 32, 99,114,101, 97,116,101, 32, 97,110, 32,101,
109,112,116,121, 32,115,111,117,114, 99,101, 10,108,111, 99, 97,108, 32,102,117,
110, 99,116,105,111,110, 32,101,109,112,116,121, 40, 41, 10, 32, 32, 32, 32,114,
101,116,117,114,110, 32,110,105,108, 10,101,110,100, 10, 10,102,117,110, 99,116,
105,111,110, 32,115,111,117,114, 99,101, 46,101,109,112,116,121, 40, 41, 10, 32,
 32, 32, 32,114,101,116,117,114,110, 32,101,109,112,116,121, 10,101,110,100, 10,
 10, 45, 45, 32,114,101,116,117,114,110,115, 32, 97, 32,115,111,117,114, 99,101,
 32,116,104, 97,116, 32,106,117,115,116, 32,111,117,116,112,117,116,115, 32, 97,
110, 32,101,114,114,111,114, 10,102,117,110, 99,116,105,111,110, 32,115,111,117,
114, 99,101, 46,101,114,114,111,114, 40,101,114,114, 41, 10, 32, 32, 32, 32,114,
101,116,117,114,110, 32,102,117,110, 99,116,105,111,110, 40, 41, 10, 32, 32, 32,
 32, 32, 32, 32, 32,114,101,116,117,114,110, 32,110,105,108, 44, 32,101,114,114,
 10, 32, 32, 32, 32,101,110,100, 10,101,110,100, 10, 10, 45, 45, 32, 99,114,101,
 97,116,101,115, 32, 97, 32,102,105,108,101, 32,115,111,117,114, 99,101, 10,102,
117,110, 99,116,105,111,110, 32,115,111,117,114, 99,101, 46,102,105,108,101, 40,
104, 97,110,100,108,101, 44, 32,105,111, 95,101,114,114, 41, 10, 32, 32, 32, 32,
105,102, 32,104, 97,110,100,108,101, 32,116,104,101,110, 10, 32, 32, 32, 32, 32,
 32, 32, 32,114,101,116,117,114,110, 32,102,117,110, 99,116,105,111,110, 40, 41,
 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,108,111, 99, 97,108, 32, 99,
104,117,110,107, 32, 61, 32,104, 97,110,100,108,101, 58,114,101, 97,100, 40, 66,
 76, 79, 67, 75, 83, 73, 90, 69, 41, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32,105,102, 32,110,111,116, 32, 99,104,117,110,107, 32,116,104,101,110, 32,
104, 97,110,100,108,101, 58, 99,108,111,115,101, 40, 41, 32,101,110,100, 10, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,114,101,116,117,114,110, 32, 99,104,
117,110,107, 10, 32, 32, 32, 32, 32, 32, 32, 32,101,110,100, 10, 32, 32, 32, 32,
101,108,115,101, 32,114,101,116,117,114,110, 32,115,111,117,114, 99,101, 46,101,
114,114,111,114, 40,105,111, 95,101,114,114, 32,111,114, 32, 34,117,110, 97, 98,
108,101, 32,116,111, 32,111,112,101,110, 32,102,105,108,101, 34, 41, 32,101,110,
100, 10,101,110,100, 10, 10, 45, 45, 32,116,117,114,110,115, 32, 97, 32,102, 97,
110, 99,121, 32,115,111,117,114, 99,101, 32,105,110,116,111, 32, 97, 32,115,105,
109,112,108,101, 32,115,111,117,114, 99,101, 10,102,117,110, 99,116,105,111,110,
 32,115,111,117,114, 99,101, 46,115,105,109,112,108,105,102,121, 40,115,114, 99,
 41, 10, 32, 32, 32, 32, 98, 97,115,101, 46, 97,115,115,101,114,116, 40,115,114,
 99, 41, 10, 32, 32, 32, 32,114,101,116,117,114,110, 32,102,117,110, 99,116,105,
111,110, 40, 41, 10, 32, 32, 32, 32, 32, 32, 32, 32,108,111, 99, 97,108, 32, 99,
104,117,110,107, 44, 32,101,114,114, 95,111,114, 95,110,101,119, 32, 61, 32,115,
114, 99, 40, 41, 10, 32, 32, 32, 32, 32, 32, 32, 32,115,114, 99, 32, 61, 32,101,
114,114, 95,111,114, 95,110,101,119, 32,111,114, 32,115,114, 99, 10, 32, 32, 32,
 32, 32, 32, 32, 32,105,102, 32,110,111,116, 32, 99,104,117,110,107, 32,116,104,
101,110, 32,114,101,116,117,114,110, 32,110,105,108, 44, 32,101,114,114, 95,111,
114, 95,110,101,119, 10, 32, 32, 32, 32, 32, 32, 32, 32,101,108,115,101, 32,114,
101,116,117,114,110, 32, 99,104,117,110,107, 32,101,110,100, 10, 32, 32, 32, 32,
101,110,100, 10,101,110,100, 10, 10, 45, 45, 32, 99,114,101, 97,116,101,115, 32,
115,116,114,105,110,103, 32,115,111,117,114, 99,101, 10,102,117,110, 99,116,105,
111,110, 32,115,111,117,114, 99,101, 46,115,116,114,105,110,103, 40,115, 41, 10,
 32, 32, 32, 32,105,102, 32,115, 32,116,104,101,110, 10, 32, 32, 32, 32, 32, 32,
 32, 32,108,111, 99, 97,108, 32,105, 32, 61, 32, 49, 10, 32, 32, 32, 32, 32, 32,
 32, 32,114,101,116,117,114,110, 32,102,117,110, 99,116,105,111,110, 40, 41, 10,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,108,111, 99, 97,108, 32, 99,104,
117,110,107, 32, 61, 32,115,116,114,105,110,103, 46,115,117, 98, 40,115, 44, 32,
105, 44, 32,105, 43, 66, 76, 79, 67, 75, 83, 73, 90, 69, 45, 49, 41, 10, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,105, 32, 61, 32,105, 32, 43, 32, 66, 76,
 79, 67, 75, 83, 73, 90, 69, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
105,102, 32, 99,104,117,110,107, 32,126, 61, 32, 34, 34, 32,116,104,101,110, 32,
114,101,116,117,114,110, 32, 99,104,117,110,107, 10, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32,101,108,115,101, 32,114,101,116,117,114,110, 32,110,105,108,
 32,101,110,100, 10, 32, 32, 32, 32, 32, 32, 32, 32,101,110,100, 10, 32, 32, 32,
 32,101,108,115,101, 32,114,101,116,117,114,110, 32,115,111,117,114, 99,101, 46,
101,109,112,116,121, 40, 41, 32,101,110,100, 10,101,110,100, 10, 10, 45, 45, 32,
 99,114,101, 97,116,101,115, 32,114,101,119,105,110,100, 97, 98,108,101, 32,115,
111,117,114, 99,101, 10,102,117,110, 99,116,105,111,110, 32,115,111,117,114, 99,
101, 46,114,101,119,105,110,100, 40,115,114, 99, 41, 10, 32, 32, 32, 32, 98, 97,
115,101, 46, 97,115,115,101,114,116, 40,115,114, 99, 41, 10, 32, 32, 32, 32,108,
111, 99, 97,108, 32,116, 32, 61, 32,123,125, 10, 32, 32, 32, 32,114,101,116,117,
114,110, 32,102,117,110, 99,116,105,111,110, 40, 99,104,117,110,107, 41, 10, 32,
 32, 32, 32, 32, 32, 32, 32,105,102, 32,110,111,116, 32, 99,104,117,110,107, 32,
116,104,101,110, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 99,104,117,
110,107, 32, 61, 32,116, 97, 98,108,101, 46,114,101,109,111,118,101, 40,116, 41,
 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,105,102, 32,110,111,116, 32,
 99,104,117,110,107, 32,116,104,101,110, 32,114,101,116,117,114,110, 32,115,114,
 99, 40, 41, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,101,108,115,101,
 32,114,101,116,117,114,110, 32, 99,104,117,110,107, 32,101,110,100, 10, 32, 32,
 32, 32, 32, 32, 32, 32,101,108,115,101, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32,116, 97, 98,108,101, 46,105,110,115,101,114,116, 40,116, 44, 32, 99,
104,117,110,107, 41, 10, 32, 32, 32, 32, 32, 32, 32, 32,101,110,100, 10, 32, 32,
 32, 32,101,110,100, 10,101,110,100, 10, 10,102,117,110, 99,116,105,111,110, 32,
115,111,117,114, 99,101, 46, 99,104, 97,105,110, 40,115,114, 99, 44, 32,102, 41,
 10, 32, 32, 32, 32, 98, 97,115,101, 46, 97,115,115,101,114,116, 40,115,114, 99,
 32, 97,110,100, 32,102, 41, 10, 32, 32, 32, 32,108,111, 99, 97,108, 32,108, 97,
115,116, 95,105,110, 44, 32,108, 97,115,116, 95,111,117,116, 32, 61, 32, 34, 34,
 44, 32, 34, 34, 10, 32, 32, 32, 32,108,111, 99, 97,108, 32,115,116, 97,116,101,
 32, 61, 32, 34,102,101,101,100,105,110,103, 34, 10, 32, 32, 32, 32,108,111, 99,
 97,108, 32,101,114,114, 10, 32, 32, 32, 32,114,101,116,117,114,110, 32,102,117,
110, 99,116,105,111,110, 40, 41, 10, 32, 32, 32, 32, 32, 32, 32, 32,105,102, 32,
110,111,116, 32,108, 97,115,116, 95,111,117,116, 32,116,104,101,110, 10, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 98, 97,115,101, 46,101,114,114,111,114,
 40, 39,115,111,117,114, 99,101, 32,105,115, 32,101,109,112,116,121, 33, 39, 44,
 32, 50, 41, 10, 32, 32, 32, 32, 32, 32, 32, 32,101,110,100, 10, 32, 32, 32, 32,
 32, 32, 32, 32,119,104,105,108,101, 32,116,114,117,101, 32,100,111, 10, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,105,102, 32,115,116, 97,116,101, 32, 61,
 61, 32, 34,102,101,101,100,105,110,103, 34, 32,116,104,101,110, 10, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,108, 97,115,116, 95,105,110,
 44, 32,101,114,114, 32, 61, 32,115,114, 99, 40, 41, 10, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,105,102, 32,101,114,114, 32,116,104,101,
110, 32,114,101,116,117,114,110, 32,110,105,108, 44, 32,101,114,114, 32,101,110,
100, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,108, 97,
115,116, 95,111,117,116, 32, 61, 32,102, 40,108, 97,115,116, 95,105,110, 41, 10,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,105,102, 32,110,
111,116, 32,108, 97,115,116, 95,111,117,116, 32,116,104,101,110, 10, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,105,102, 32,
108, 97,115,116, 95,105,110, 32,116,104,101,110, 10, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 98, 97,115,
101, 46,101,114,114,111,114, 40, 39,102,105,108,116,101,114, 32,114,101,116,117,
114,110,101,100, 32,105,110, 97,112,112,114,111,112,114,105, 97,116,101, 32,110,
105,108, 39, 41, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32,101,108,115,101, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,114,101,116,117,114,110,
 32,110,105,108, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32,101,110,100, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32,101,108,115,101,105,102, 32,108, 97,115,116, 95,111,117,116,
 32,126, 61, 32, 34, 34, 32,116,104,101,110, 10, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,115,116, 97,116,101, 32, 61, 32,
 34,101, 97,116,105,110,103, 34, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32,105,102, 32,108, 97,115,116, 95,105,110, 32,
116,104,101,110, 32,108, 97,115,116, 95,105,110, 32, 61, 32, 34, 34, 32,101,110,
100, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32,114,101,116,117,114,110, 32,108, 97,115,116, 95,111,117,116, 10, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,101,110,100, 10, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,101,108,115,101, 10, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,108, 97,115,116, 95,111,117,116, 32,
 61, 32,102, 40,108, 97,115,116, 95,105,110, 41, 10, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32,105,102, 32,108, 97,115,116, 95,111,117,116,
 32, 61, 61, 32, 34, 34, 32,116,104,101,110, 10, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,105,102, 32,108, 97,115,116, 95,
105,110, 32, 61, 61, 32, 34, 34, 32,116,104,101,110, 10, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,115,116,
 97,116,101, 32, 61, 32, 34,102,101,101,100,105,110,103, 34, 10, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,101,108,115,101,
 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 98, 97,115,101, 46,101,114,114,111,114, 40, 39,102,105,108,
116,101,114, 32,114,101,116,117,114,110,101,100, 32, 34, 34, 39, 41, 10, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,101,110,
100, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,101,108,
115,101,105,102, 32,110,111,116, 32,108, 97,115,116, 95,111,117,116, 32,116,104,
101,110, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32,105,102, 32,108, 97,115,116, 95,105,110, 32,116,104,101,110, 10, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 98, 97,115,101, 46,101,114,114,111,114, 40, 39,102,105,108,116,101,
114, 32,114,101,116,117,114,110,101,100, 32,105,110, 97,112,112,114,111,112,114,
105, 97,116,101, 32,110,105,108, 39, 41, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,101,108,115,101, 10, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
114,101,116,117,114,110, 32,110,105,108, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,101,110,100, 10, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,101,108,115,101, 10, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,114,101,116,117,
114,110, 32,108, 97,115,116, 95,111,117,116, 10, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32,101,110,100, 10, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32,101,110,100, 10, 32, 32, 32, 32, 32, 32, 32, 32,101,110,100, 10,
 32, 32, 32, 32,101,110,100, 10,101,110,100, 10, 10, 45, 45, 32, 99,114,101, 97,
116,101,115, 32, 97, 32,115,111,117,114, 99,101, 32,116,104, 97,116, 32,112,114,
111,100,117, 99,101,115, 32, 99,111,110,116,101,110,116,115, 32,111,102, 32,115,
101,118,101,114, 97,108, 32,115,111,117,114, 99,101,115, 44, 32,111,110,101, 32,
 97,102,116,101,114, 32,116,104,101, 10, 45, 45, 32,111,116,104,101,114, 44, 32,
 97,115, 32,105,102, 32,116,104,101,121, 32,119,101,114,101, 32, 99,111,110, 99,
 97,116,101,110, 97,116,101,100, 10, 45, 45, 32, 40,116,104, 97,110,107,115, 32,
116,111, 32, 87,105,109, 32, 67,111,117,119,101,110, 98,101,114,103, 41, 10,102,
117,110, 99,116,105,111,110, 32,115,111,117,114, 99,101, 46, 99, 97,116, 40, 46,
 46, 46, 41, 10, 32, 32, 32, 32,108,111, 99, 97,108, 32,115,114, 99, 32, 61, 32,
116, 97, 98,108,101, 46,114,101,109,111,118,101, 40, 97,114,103, 44, 32, 49, 41,
 10, 32, 32, 32, 32,114,101,116,117,114,110, 32,102,117,110, 99,116,105,111,110,
 40, 41, 10, 32, 32, 32, 32, 32, 32, 32, 32,119,104,105,108,101, 32,115,114, 99,
 32,100,111, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,108,111, 99, 97,
108, 32, 99,104,117,110,107, 44, 32,101,114,114, 32, 61, 32,115,114, 99, 40, 41,
 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,105,102, 32, 99,104,117,110,
107, 32,116,104,101,110, 32,114,101,116,117,114,110, 32, 99,104,117,110,107, 32,
101,110,100, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,105,102, 32,101,
114,114, 32,116,104,101,110, 32,114,101,116,117,114,110, 32,110,105,108, 44, 32,
101,114,114, 32,101,110,100, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
115,114, 99, 32, 61, 32,116, 97, 98,108,101, 46,114,101,109,111,118,101, 40, 97,
114,103, 44, 32, 49, 41, 10, 32, 32, 32, 32, 32, 32, 32, 32,101,110,100, 10, 32,
 32, 32, 32,101,110,100, 10,101,110,100, 10, 10, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 10, 45, 45, 32, 83,105,110,107, 32,115,116,
117,102,102, 10, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 10, 45, 45, 32, 99,114,101, 97,116,101,115, 32, 97, 32,115,105,110,107, 32,
116,104, 97,116, 32,115,116,111,114,101,115, 32,105,110,116,111, 32, 97, 32,116,
 97, 98,108,101, 10,102,117,110, 99,116,105,111,110, 32,115,105,110,107, 46,116,
 97, 98,108,101, 40,116, 41, 10, 32, 32, 32, 32,116, 32, 61, 32,116, 32,111,114,
 32,123,125, 10, 32, 32, 32, 32,108,111, 99, 97,108, 32,102, 32, 61, 32,102,117,
110, 99,116,105,111,110, 40, 99,104,117,110,107, 44, 32,101,114,114, 41, 10, 32,
 32, 32, 32, 32, 32, 32, 32,105,102, 32, 99,104,117,110,107, 32,116,104,101,110,
 32,116, 97, 98,108,101, 46,105,110,115,101,114,116, 40,116, 44, 32, 99,104,117,
110,107, 41, 32,101,110,100, 10, 32, 32, 32, 32, 32, 32, 32, 32,114,101,116,117,
114,110, 32, 49, 10, 32, 32, 32, 32,101,110,100, 10, 32, 32, 32, 32,114,101,116,
117,114,110, 32,102, 44, 32,116, 10,101,110,100, 10, 10, 45, 45, 32,116,117,114,
110,115, 32, 97, 32,102, 97,110, 99,121, 32,115,105,110,107, 32,105,110,116,111,
 32, 97, 32,115,105,109,112,108,101, 32,115,105,110,107, 10,102,117,110, 99,116,
105,111,110, 32,115,105,110,107, 46,115,105,109,112,108,105,102,121, 40,115,110,
107, 41, 10, 32, 32, 32, 32, 98, 97,115,101, 46, 97,115,115,101,114,116, 40,115,
110,107, 41, 10, 32, 32, 32, 32,114,101,116,117,114,110, 32,102,117,110, 99,116,
105,111,110, 40, 99,104,117,110,107, 44, 32,101,114,114, 41, 10, 32, 32, 32, 32,
 32, 32, 32, 32,108,111, 99, 97,108, 32,114,101,116, 44, 32,101,114,114, 95,111,
114, 95,110,101,119, 32, 61, 32,115,110,107, 40, 99,104,117,110,107, 44, 32,101,
114,114, 41, 10, 32, 32, 32, 32, 32, 32, 32, 32,105,102, 32,110,111,116, 32,114,
101,116, 32,116,104,101,110, 32,114,101,116,117,114,110, 32,110,105,108, 44, 32,
101,114,114, 95,111,114, 95,110,101,119, 32,101,110,100, 10, 32, 32, 32, 32, 32,
 32, 32, 32,115,110,107, 32, 61, 32,101,114,114, 95,111,114, 95,110,101,119, 32,
111,114, 32,115,110,107, 10, 32, 32, 32, 32, 32, 32, 32, 32,114,101,116,117,114,
110, 32, 49, 10, 32, 32, 32, 32,101,110,100, 10,101,110,100, 10, 10, 45, 45, 32,
 99,114,101, 97,116,101,115, 32, 97, 32,102,105,108,101, 32,115,105,110,107, 10,
102,117,110, 99,116,105,111,110, 32,115,105,110,107, 46,102,105,108,101, 40,104,
 97,110,100,108,101, 44, 32,105,111, 95,101,114,114, 41, 10, 32, 32, 32, 32,105,
102, 32,104, 97,110,100,108,101, 32,116,104,101,110, 10, 32, 32, 32, 32, 32, 32,
 32, 32,114,101,116,117,114,110, 32,102,117,110, 99,116,105,111,110, 40, 99,104,
117,110,107, 44, 32,101,114,114, 41, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32,105,102, 32,110,111,116, 32, 99,104,117,110,107, 32,116,104,101,110, 10,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,104, 97,110,100,
108,101, 58, 99,108,111,115,101, 40, 41, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32,114,101,116,117,114,110, 32, 49, 10, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32,101,108,115,101, 32,114,101,116,117,114,110, 32,
104, 97,110,100,108,101, 58,119,114,105,116,101, 40, 99,104,117,110,107, 41, 32,
101,110,100, 10, 32, 32, 32, 32, 32, 32, 32, 32,101,110,100, 10, 32, 32, 32, 32,
101,108,115,101, 32,114,101,116,117,114,110, 32,115,105,110,107, 46,101,114,114,
111,114, 40,105,111, 95,101,114,114, 32,111,114, 32, 34,117,110, 97, 98,108,101,
 32,116,111, 32,111,112,101,110, 32,102,105,108,101, 34, 41, 32,101,110,100, 10,
101,110,100, 10, 10, 45, 45, 32, 99,114,101, 97,116,101,115, 32, 97, 32,115,105,
110,107, 32,116,104, 97,116, 32,100,105,115, 99, 97,114,100,115, 32,100, 97,116,
 97, 10,108,111, 99, 97,108, 32,102,117,110, 99,116,105,111,110, 32,110,117,108,
108, 40, 41, 10, 32, 32, 32, 32,114,101,116,117,114,110, 32, 49, 10,101,110,100,
 10, 10,102,117,110, 99,116,105,111,110, 32,115,105,110,107, 46,110,117,108,108,
 40, 41, 10, 32, 32, 32, 32,114,101,116,117,114,110, 32,110,117,108,108, 10,101,
110,100, 10, 10, 45, 45, 32, 99,114,101, 97,116,101,115, 32, 97, 32,115,105,110,
107, 32,116,104, 97,116, 32,106,117,115,116, 32,114,101,116,117,114,110,115, 32,
 97,110, 32,101,114,114,111,114, 10,102,117,110, 99,116,105,111,110, 32,115,105,
110,107, 46,101,114,114,111,114, 40,101,114,114, 41, 10, 32, 32, 32, 32,114,101,
116,117,114,110, 32,102,117,110, 99,116,105,111,110, 40, 41, 10, 32, 32, 32, 32,
 32, 32, 32, 32,114,101,116,117,114,110, 32,110,105,108, 44, 32,101,114,114, 10,
 32, 32, 32, 32,101,110,100, 10,101,110,100, 10, 10, 45, 45, 32, 99,104, 97,105,
110,115, 32, 97, 32,115,105,110,107, 32,119,105,116,104, 32, 97, 32,102,105,108,
116,101,114, 10,102,117,110, 99,116,105,111,110, 32,115,105,110,107, 46, 99,104,
 97,105,110, 40,102, 44, 32,115,110,107, 41, 10, 32, 32, 32, 32, 98, 97,115,101,
 46, 97,115,115,101,114,116, 40,102, 32, 97,110,100, 32,115,110,107, 41, 10, 32,
 32, 32, 32,114,101,116,117,114,110, 32,102,117,110, 99,116,105,111,110, 40, 99,
104,117,110,107, 44, 32,101,114,114, 41, 10, 32, 32, 32, 32, 32, 32, 32, 32,105,
102, 32, 99,104,117,110,107, 32,126, 61, 32, 34, 34, 32,116,104,101,110, 10, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,108,111, 99, 97,108, 32,102,105,108,
116,101,114,101,100, 32, 61, 32,102, 40, 99,104,117,110,107, 41, 10, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32,108,111, 99, 97,108, 32,100,111,110,101, 32,
 61, 32, 99,104,117,110,107, 32, 97,110,100, 32, 34, 34, 10, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32,119,104,105,108,101, 32,116,114,117,101, 32,100,111,
 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,108,111, 99,
 97,108, 32,114,101,116, 44, 32,115,110,107,101,114,114, 32, 61, 32,115,110,107,
 40,102,105,108,116,101,114,101,100, 44, 32,101,114,114, 41, 10, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,105,102, 32,110,111,116, 32,114,
101,116, 32,116,104,101,110, 32,114,101,116,117,114,110, 32,110,105,108, 44, 32,
115,110,107,101,114,114, 32,101,110,100, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32,105,102, 32,102,105,108,116,101,114,101,100, 32, 61,
 61, 32,100,111,110,101, 32,116,104,101,110, 32,114,101,116,117,114,110, 32, 49,
 32,101,110,100, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32,102,105,108,116,101,114,101,100, 32, 61, 32,102, 40,100,111,110,101, 41, 10,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,101,110,100, 10, 32, 32, 32, 32,
 32, 32, 32, 32,101,108,115,101, 32,114,101,116,117,114,110, 32, 49, 32,101,110,
100, 10, 32, 32, 32, 32,101,110,100, 10,101,110,100, 10, 10, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10, 45, 45, 32, 80,117,109,112,
 32,115,116,117,102,102, 10, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 10, 45, 45, 32,112,117,109,112,115, 32,111,110,101, 32, 99,104,
117,110,107, 32,102,114,111,109, 32,116,104,101, 32,115,111,117,114, 99,101, 32,
116,111, 32,116,104,101, 32,115,105,110,107, 10,102,117,110, 99,116,105,111,110,
 32,112,117,109,112, 46,115,116,101,112, 40,115,114, 99, 44, 32,115,110,107, 41,
 10, 32, 32, 32, 32,108,111, 99, 97,108, 32, 99,104,117,110,107, 44, 32,115,114,
 99, 95,101,114,114, 32, 61, 32,115,114, 99, 40, 41, 10, 32, 32, 32, 32,108,111,
 99, 97,108, 32,114,101,116, 44, 32,115,110,107, 95,101,114,114, 32, 61, 32,115,
110,107, 40, 99,104,117,110,107, 44, 32,115,114, 99, 95,101,114,114, 41, 10, 32,
 32, 32, 32,105,102, 32, 99,104,117,110,107, 32, 97,110,100, 32,114,101,116, 32,
116,104,101,110, 32,114,101,116,117,114,110, 32, 49, 10, 32, 32, 32, 32,101,108,
115,101, 32,114,101,116,117,114,110, 32,110,105,108, 44, 32,115,114, 99, 95,101,
114,114, 32,111,114, 32,115,110,107, 95,101,114,114, 32,101,110,100, 10,101,110,
100, 10, 10, 45, 45, 32,112,117,109,112,115, 32, 97,108,108, 32,100, 97,116, 97,
 32,102,114,111,109, 32, 97, 32,115,111,117,114, 99,101, 32,116,111, 32, 97, 32,
115,105,110,107, 44, 32,117,115,105,110,103, 32, 97, 32,115,116,101,112, 32,102,
117,110, 99,116,105,111,110, 10,102,117,110, 99,116,105,111,110, 32,112,117,109,
112, 46, 97,108,108, 40,115,114, 99, 44, 32,115,110,107, 44, 32,115,116,101,112,
 41, 10, 32, 32, 32, 32, 98, 97,115,101, 46, 97,115,115,101,114,116, 40,115,114,
 99, 32, 97,110,100, 32,115,110,107, 41, 10, 32, 32, 32, 32,115,116,101,112, 32,
 61, 32,115,116,101,112, 32,111,114, 32,112,117,109,112, 46,115,116,101,112, 10,
 32, 32, 32, 32,119,104,105,108,101, 32,116,114,117,101, 32,100,111, 10, 32, 32,
 32, 32, 32, 32, 32, 32,108,111, 99, 97,108, 32,114,101,116, 44, 32,101,114,114,
 32, 61, 32,115,116,101,112, 40,115,114, 99, 44, 32,115,110,107, 41, 10, 32, 32,
 32, 32, 32, 32, 32, 32,105,102, 32,110,111,116, 32,114,101,116, 32,116,104,101,
110, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,105,102, 32,101,114,114,
 32,116,104,101,110, 32,114,101,116,117,114,110, 32,110,105,108, 44, 32,101,114,
114, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,101,108,115,101, 32,114,
101,116,117,114,110, 32, 49, 32,101,110,100, 10, 32, 32, 32, 32, 32, 32, 32, 32,
101,110,100, 10, 32, 32, 32, 32,101,110,100, 10,101,110,100, 10, 10,
};

 if (luaL_loadbuffer(L,(const char*)B1,sizeof(B1),"ltn12.lua")==0) lua_call(L, 0, 0);
}
