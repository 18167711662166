/* code automatically generated by bin2c -- DO NOT EDIT */
{
/* #include'ing this file in a C program is equivalent to calling
  if (luaL_loadfile(L,"tp.lua")==0) lua_call(L, 0, 0); 
*/
/* tp.lua */
static const unsigned char B1[]={
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10, 45, 45,
 32, 85,110,105,102,105,101,100, 32, 83, 77, 84, 80, 47, 70, 84, 80, 32,115,117,
 98,115,121,115,116,101,109, 10, 45, 45, 32, 76,117, 97, 83,111, 99,107,101,116,
 32,116,111,111,108,107,105,116, 46, 10, 45, 45, 32, 65,117,116,104,111,114, 58,
 32, 68,105,101,103,111, 32, 78,101,104, 97, 98, 10, 45, 45, 32, 82, 67, 83, 32,
 73, 68, 58, 32, 36, 73,100, 58, 32,116,112, 46,108,117, 97, 44,118, 32, 49, 46,
 50, 50, 32, 50, 48, 48, 54, 47, 48, 51, 47, 49, 52, 32, 48, 57, 58, 48, 52, 58,
 49, 53, 32,100,105,101,103,111, 32, 69,120,112, 32, 36, 10, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10, 10, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10, 45, 45, 32, 68,101, 99,108, 97,
114,101, 32,109,111,100,117,108,101, 32, 97,110,100, 32,105,109,112,111,114,116,
 32,100,101,112,101,110,100,101,110, 99,105,101,115, 10, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10,108,111, 99, 97,108, 32, 98, 97,
115,101, 32, 61, 32, 95, 71, 10,108,111, 99, 97,108, 32,115,116,114,105,110,103,
 32, 61, 32,114,101,113,117,105,114,101, 40, 34,115,116,114,105,110,103, 34, 41,
 10,108,111, 99, 97,108, 32,115,111, 99,107,101,116, 32, 61, 32,114,101,113,117,
105,114,101, 40, 34,115,111, 99,107,101,116, 34, 41, 10,108,111, 99, 97,108, 32,
108,116,110, 49, 50, 32, 61, 32,114,101,113,117,105,114,101, 40, 34,108,116,110,
 49, 50, 34, 41, 10,109,111,100,117,108,101, 40, 34,115,111, 99,107,101,116, 46,
116,112, 34, 41, 10, 10, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 10, 45, 45, 32, 80,114,111,103,114, 97,109, 32, 99,111,110,115,116,
 97,110,116,115, 10, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 10, 84, 73, 77, 69, 79, 85, 84, 32, 61, 32, 54, 48, 10, 10, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10, 45, 45, 32, 73,109,
112,108,101,109,101,110,116, 97,116,105,111,110, 10, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10, 45, 45, 32,103,101,116,115, 32,115,
101,114,118,101,114, 32,114,101,112,108,121, 32, 40,119,111,114,107,115, 32,102,
111,114, 32, 83, 77, 84, 80, 32, 97,110,100, 32, 70, 84, 80, 41, 10,108,111, 99,
 97,108, 32,102,117,110, 99,116,105,111,110, 32,103,101,116, 95,114,101,112,108,
121, 40, 99, 41, 10, 32, 32, 32, 32,108,111, 99, 97,108, 32, 99,111,100,101, 44,
 32, 99,117,114,114,101,110,116, 44, 32,115,101,112, 10, 32, 32, 32, 32,108,111,
 99, 97,108, 32,108,105,110,101, 44, 32,101,114,114, 32, 61, 32, 99, 58,114,101,
 99,101,105,118,101, 40, 41, 10, 32, 32, 32, 32,108,111, 99, 97,108, 32,114,101,
112,108,121, 32, 61, 32,108,105,110,101, 10, 32, 32, 32, 32,105,102, 32,101,114,
114, 32,116,104,101,110, 32,114,101,116,117,114,110, 32,110,105,108, 44, 32,101,
114,114, 32,101,110,100, 10, 32, 32, 32, 32, 99,111,100,101, 44, 32,115,101,112,
 32, 61, 32,115,111, 99,107,101,116, 46,115,107,105,112, 40, 50, 44, 32,115,116,
114,105,110,103, 46,102,105,110,100, 40,108,105,110,101, 44, 32, 34, 94, 40, 37,
100, 37,100, 37,100, 41, 40, 46, 63, 41, 34, 41, 41, 10, 32, 32, 32, 32,105,102,
 32,110,111,116, 32, 99,111,100,101, 32,116,104,101,110, 32,114,101,116,117,114,
110, 32,110,105,108, 44, 32, 34,105,110,118, 97,108,105,100, 32,115,101,114,118,
101,114, 32,114,101,112,108,121, 34, 32,101,110,100, 10, 32, 32, 32, 32,105,102,
 32,115,101,112, 32, 61, 61, 32, 34, 45, 34, 32,116,104,101,110, 32, 45, 45, 32,
114,101,112,108,121, 32,105,115, 32,109,117,108,116,105,108,105,110,101, 10, 32,
 32, 32, 32, 32, 32, 32, 32,114,101,112,101, 97,116, 10, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32,108,105,110,101, 44, 32,101,114,114, 32, 61, 32, 99, 58,
114,101, 99,101,105,118,101, 40, 41, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32,105,102, 32,101,114,114, 32,116,104,101,110, 32,114,101,116,117,114,110,
 32,110,105,108, 44, 32,101,114,114, 32,101,110,100, 10, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 99,117,114,114,101,110,116, 44, 32,115,101,112, 32, 61,
 32,115,111, 99,107,101,116, 46,115,107,105,112, 40, 50, 44, 32,115,116,114,105,
110,103, 46,102,105,110,100, 40,108,105,110,101, 44, 32, 34, 94, 40, 37,100, 37,
100, 37,100, 41, 40, 46, 63, 41, 34, 41, 41, 10, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32,114,101,112,108,121, 32, 61, 32,114,101,112,108,121, 32, 46, 46,
 32, 34, 92,110, 34, 32, 46, 46, 32,108,105,110,101, 10, 32, 32, 32, 32, 32, 32,
 32, 32, 45, 45, 32,114,101,112,108,121, 32,101,110,100,115, 32,119,105,116,104,
 32,115, 97,109,101, 32, 99,111,100,101, 10, 32, 32, 32, 32, 32, 32, 32, 32,117,
110,116,105,108, 32, 99,111,100,101, 32, 61, 61, 32, 99,117,114,114,101,110,116,
 32, 97,110,100, 32,115,101,112, 32, 61, 61, 32, 34, 32, 34, 10, 32, 32, 32, 32,
101,110,100, 10, 32, 32, 32, 32,114,101,116,117,114,110, 32, 99,111,100,101, 44,
 32,114,101,112,108,121, 10,101,110,100, 10, 10, 45, 45, 32,109,101,116, 97,116,
 97, 98,108,101, 32,102,111,114, 32,115,111, 99,107, 32,111, 98,106,101, 99,116,
 10,108,111, 99, 97,108, 32,109,101,116, 97,116, 32, 61, 32,123, 32, 95, 95,105,
110,100,101,120, 32, 61, 32,123,125, 32,125, 10, 10,102,117,110, 99,116,105,111,
110, 32,109,101,116, 97,116, 46, 95, 95,105,110,100,101,120, 58, 99,104,101, 99,
107, 40,111,107, 41, 10, 32, 32, 32, 32,108,111, 99, 97,108, 32, 99,111,100,101,
 44, 32,114,101,112,108,121, 32, 61, 32,103,101,116, 95,114,101,112,108,121, 40,
115,101,108,102, 46, 99, 41, 10, 32, 32, 32, 32,105,102, 32,110,111,116, 32, 99,
111,100,101, 32,116,104,101,110, 32,114,101,116,117,114,110, 32,110,105,108, 44,
 32,114,101,112,108,121, 32,101,110,100, 10, 32, 32, 32, 32,105,102, 32, 98, 97,
115,101, 46,116,121,112,101, 40,111,107, 41, 32,126, 61, 32, 34,102,117,110, 99,
116,105,111,110, 34, 32,116,104,101,110, 10, 32, 32, 32, 32, 32, 32, 32, 32,105,
102, 32, 98, 97,115,101, 46,116,121,112,101, 40,111,107, 41, 32, 61, 61, 32, 34,
116, 97, 98,108,101, 34, 32,116,104,101,110, 10, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32,102,111,114, 32,105, 44, 32,118, 32,105,110, 32, 98, 97,115,101,
 46,105,112, 97,105,114,115, 40,111,107, 41, 32,100,111, 10, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,105,102, 32,115,116,114,105,110,103,
 46,102,105,110,100, 40, 99,111,100,101, 44, 32,118, 41, 32,116,104,101,110, 10,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
114,101,116,117,114,110, 32, 98, 97,115,101, 46,116,111,110,117,109, 98,101,114,
 40, 99,111,100,101, 41, 44, 32,114,101,112,108,121, 10, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,101,110,100, 10, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32,101,110,100, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32,114,101,116,117,114,110, 32,110,105,108, 44, 32,114,101,112,108,121, 10,
 32, 32, 32, 32, 32, 32, 32, 32,101,108,115,101, 10, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32,105,102, 32,115,116,114,105,110,103, 46,102,105,110,100, 40,
 99,111,100,101, 44, 32,111,107, 41, 32,116,104,101,110, 32,114,101,116,117,114,
110, 32, 98, 97,115,101, 46,116,111,110,117,109, 98,101,114, 40, 99,111,100,101,
 41, 44, 32,114,101,112,108,121, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32,101,108,115,101, 32,114,101,116,117,114,110, 32,110,105,108, 44, 32,114,101,
112,108,121, 32,101,110,100, 10, 32, 32, 32, 32, 32, 32, 32, 32,101,110,100, 10,
 32, 32, 32, 32,101,108,115,101, 32,114,101,116,117,114,110, 32,111,107, 40, 98,
 97,115,101, 46,116,111,110,117,109, 98,101,114, 40, 99,111,100,101, 41, 44, 32,
114,101,112,108,121, 41, 32,101,110,100, 10,101,110,100, 10, 10,102,117,110, 99,
116,105,111,110, 32,109,101,116, 97,116, 46, 95, 95,105,110,100,101,120, 58, 99,
111,109,109, 97,110,100, 40, 99,109,100, 44, 32, 97,114,103, 41, 10, 32, 32, 32,
 32,105,102, 32, 97,114,103, 32,116,104,101,110, 10, 32, 32, 32, 32, 32, 32, 32,
 32,114,101,116,117,114,110, 32,115,101,108,102, 46, 99, 58,115,101,110,100, 40,
 99,109,100, 32, 46, 46, 32, 34, 32, 34, 32, 46, 46, 32, 97,114,103, 46, 46, 32,
 34, 92,114, 92,110, 34, 41, 10, 32, 32, 32, 32,101,108,115,101, 10, 32, 32, 32,
 32, 32, 32, 32, 32,114,101,116,117,114,110, 32,115,101,108,102, 46, 99, 58,115,
101,110,100, 40, 99,109,100, 32, 46, 46, 32, 34, 92,114, 92,110, 34, 41, 10, 32,
 32, 32, 32,101,110,100, 10,101,110,100, 10, 10,102,117,110, 99,116,105,111,110,
 32,109,101,116, 97,116, 46, 95, 95,105,110,100,101,120, 58,115,105,110,107, 40,
115,110,107, 44, 32,112, 97,116, 41, 10, 32, 32, 32, 32,108,111, 99, 97,108, 32,
 99,104,117,110,107, 44, 32,101,114,114, 32, 61, 32, 99, 58,114,101, 99,101,105,
118,101, 40,112, 97,116, 41, 10, 32, 32, 32, 32,114,101,116,117,114,110, 32,115,
110,107, 40, 99,104,117,110,107, 44, 32,101,114,114, 41, 10,101,110,100, 10, 10,
102,117,110, 99,116,105,111,110, 32,109,101,116, 97,116, 46, 95, 95,105,110,100,
101,120, 58,115,101,110,100, 40,100, 97,116, 97, 41, 10, 32, 32, 32, 32,114,101,
116,117,114,110, 32,115,101,108,102, 46, 99, 58,115,101,110,100, 40,100, 97,116,
 97, 41, 10,101,110,100, 10, 10,102,117,110, 99,116,105,111,110, 32,109,101,116,
 97,116, 46, 95, 95,105,110,100,101,120, 58,114,101, 99,101,105,118,101, 40,112,
 97,116, 41, 10, 32, 32, 32, 32,114,101,116,117,114,110, 32,115,101,108,102, 46,
 99, 58,114,101, 99,101,105,118,101, 40,112, 97,116, 41, 10,101,110,100, 10, 10,
102,117,110, 99,116,105,111,110, 32,109,101,116, 97,116, 46, 95, 95,105,110,100,
101,120, 58,103,101,116,102,100, 40, 41, 10, 32, 32, 32, 32,114,101,116,117,114,
110, 32,115,101,108,102, 46, 99, 58,103,101,116,102,100, 40, 41, 10,101,110,100,
 10, 10,102,117,110, 99,116,105,111,110, 32,109,101,116, 97,116, 46, 95, 95,105,
110,100,101,120, 58,100,105,114,116,121, 40, 41, 10, 32, 32, 32, 32,114,101,116,
117,114,110, 32,115,101,108,102, 46, 99, 58,100,105,114,116,121, 40, 41, 10,101,
110,100, 10, 10,102,117,110, 99,116,105,111,110, 32,109,101,116, 97,116, 46, 95,
 95,105,110,100,101,120, 58,103,101,116, 99,111,110,116,114,111,108, 40, 41, 10,
 32, 32, 32, 32,114,101,116,117,114,110, 32,115,101,108,102, 46, 99, 10,101,110,
100, 10, 10,102,117,110, 99,116,105,111,110, 32,109,101,116, 97,116, 46, 95, 95,
105,110,100,101,120, 58,115,111,117,114, 99,101, 40,115,111,117,114, 99,101, 44,
 32,115,116,101,112, 41, 10, 32, 32, 32, 32,108,111, 99, 97,108, 32,115,105,110,
107, 32, 61, 32,115,111, 99,107,101,116, 46,115,105,110,107, 40, 34,107,101,101,
112, 45,111,112,101,110, 34, 44, 32,115,101,108,102, 46, 99, 41, 10, 32, 32, 32,
 32,108,111, 99, 97,108, 32,114,101,116, 44, 32,101,114,114, 32, 61, 32,108,116,
110, 49, 50, 46,112,117,109,112, 46, 97,108,108, 40,115,111,117,114, 99,101, 44,
 32,115,105,110,107, 44, 32,115,116,101,112, 32,111,114, 32,108,116,110, 49, 50,
 46,112,117,109,112, 46,115,116,101,112, 41, 10, 32, 32, 32, 32,114,101,116,117,
114,110, 32,114,101,116, 44, 32,101,114,114, 10,101,110,100, 10, 10, 45, 45, 32,
 99,108,111,115,101,115, 32,116,104,101, 32,117,110,100,101,114,108,121,105,110,
103, 32, 99, 10,102,117,110, 99,116,105,111,110, 32,109,101,116, 97,116, 46, 95,
 95,105,110,100,101,120, 58, 99,108,111,115,101, 40, 41, 10, 32, 32, 32, 32,115,
101,108,102, 46, 99, 58, 99,108,111,115,101, 40, 41, 10,  9,114,101,116,117,114,
110, 32, 49, 10,101,110,100, 10, 10, 45, 45, 32, 99,111,110,110,101, 99,116, 32,
119,105,116,104, 32,115,101,114,118,101,114, 32, 97,110,100, 32,114,101,116,117,
114,110, 32, 99, 32,111, 98,106,101, 99,116, 10,102,117,110, 99,116,105,111,110,
 32, 99,111,110,110,101, 99,116, 40,104,111,115,116, 44, 32,112,111,114,116, 44,
 32,116,105,109,101,111,117,116, 44, 32, 99,114,101, 97,116,101, 41, 10, 32, 32,
 32, 32,108,111, 99, 97,108, 32, 99, 44, 32,101, 32, 61, 32, 40, 99,114,101, 97,
116,101, 32,111,114, 32,115,111, 99,107,101,116, 46,116, 99,112, 41, 40, 41, 10,
 32, 32, 32, 32,105,102, 32,110,111,116, 32, 99, 32,116,104,101,110, 32,114,101,
116,117,114,110, 32,110,105,108, 44, 32,101, 32,101,110,100, 10, 32, 32, 32, 32,
 99, 58,115,101,116,116,105,109,101,111,117,116, 40,116,105,109,101,111,117,116,
 32,111,114, 32, 84, 73, 77, 69, 79, 85, 84, 41, 10, 32, 32, 32, 32,108,111, 99,
 97,108, 32,114, 44, 32,101, 32, 61, 32, 99, 58, 99,111,110,110,101, 99,116, 40,
104,111,115,116, 44, 32,112,111,114,116, 41, 10, 32, 32, 32, 32,105,102, 32,110,
111,116, 32,114, 32,116,104,101,110, 10, 32, 32, 32, 32, 32, 32, 32, 32, 99, 58,
 99,108,111,115,101, 40, 41, 10, 32, 32, 32, 32, 32, 32, 32, 32,114,101,116,117,
114,110, 32,110,105,108, 44, 32,101, 10, 32, 32, 32, 32,101,110,100, 10, 32, 32,
 32, 32,114,101,116,117,114,110, 32, 98, 97,115,101, 46,115,101,116,109,101,116,
 97,116, 97, 98,108,101, 40,123, 99, 32, 61, 32, 99,125, 44, 32,109,101,116, 97,
116, 41, 10,101,110,100, 10, 10,
};

 if (luaL_loadbuffer(L,(const char*)B1,sizeof(B1),"tp.lua")==0) lua_call(L, 0, 0);
}
