/* code automatically generated by bin2c -- DO NOT EDIT */
{
/* #include'ing this file in a C program is equivalent to calling
  if (luaL_loadfile(L,"url.lua")==0) lua_call(L, 0, 0); 
*/
/* url.lua */
static const unsigned char B1[]={
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10, 45, 45,
 32, 85, 82, 73, 32,112, 97,114,115,105,110,103, 44, 32, 99,111,109,112,111,115,
105,116,105,111,110, 32, 97,110,100, 32,114,101,108, 97,116,105,118,101, 32, 85,
 82, 76, 32,114,101,115,111,108,117,116,105,111,110, 10, 45, 45, 32, 76,117, 97,
 83,111, 99,107,101,116, 32,116,111,111,108,107,105,116, 46, 10, 45, 45, 32, 65,
117,116,104,111,114, 58, 32, 68,105,101,103,111, 32, 78,101,104, 97, 98, 10, 45,
 45, 32, 82, 67, 83, 32, 73, 68, 58, 32, 36, 73,100, 58, 32,117,114,108, 46,108,
117, 97, 44,118, 32, 49, 46, 51, 56, 32, 50, 48, 48, 54, 47, 48, 52, 47, 48, 51,
 32, 48, 52, 58, 52, 53, 58, 52, 50, 32,100,105,101,103,111, 32, 69,120,112, 32,
 36, 10, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10,
 10, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10, 45,
 45, 32, 68,101, 99,108, 97,114,101, 32,109,111,100,117,108,101, 10, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10,108,111, 99, 97,108,
 32,115,116,114,105,110,103, 32, 61, 32,114,101,113,117,105,114,101, 40, 34,115,
116,114,105,110,103, 34, 41, 10,108,111, 99, 97,108, 32, 98, 97,115,101, 32, 61,
 32, 95, 71, 10,108,111, 99, 97,108, 32,116, 97, 98,108,101, 32, 61, 32,114,101,
113,117,105,114,101, 40, 34,116, 97, 98,108,101, 34, 41, 10,109,111,100,117,108,
101, 40, 34,115,111, 99,107,101,116, 46,117,114,108, 34, 41, 10, 10, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10, 45, 45, 32, 77,111,
100,117,108,101, 32,118,101,114,115,105,111,110, 10, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10, 95, 86, 69, 82, 83, 73, 79, 78, 32,
 61, 32, 34, 85, 82, 76, 32, 49, 46, 48, 46, 49, 34, 10, 10, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10, 45, 45, 32, 69,110, 99,111,
100,101,115, 32, 97, 32,115,116,114,105,110,103, 32,105,110,116,111, 32,105,116,
115, 32,101,115, 99, 97,112,101,100, 32,104,101,120, 97,100,101, 99,105,109, 97,
108, 32,114,101,112,114,101,115,101,110,116, 97,116,105,111,110, 10, 45, 45, 32,
 73,110,112,117,116, 10, 45, 45, 32, 32, 32,115, 58, 32, 98,105,110, 97,114,121,
 32,115,116,114,105,110,103, 32,116,111, 32, 98,101, 32,101,110, 99,111,100,101,
100, 10, 45, 45, 32, 82,101,116,117,114,110,115, 10, 45, 45, 32, 32, 32,101,115,
 99, 97,112,101,100, 32,114,101,112,114,101,115,101,110,116, 97,116,105,111,110,
 32,111,102, 32,115,116,114,105,110,103, 32, 98,105,110, 97,114,121, 10, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10,102,117,110, 99,
116,105,111,110, 32,101,115, 99, 97,112,101, 40,115, 41, 10, 32, 32, 32, 32,114,
101,116,117,114,110, 32,115,116,114,105,110,103, 46,103,115,117, 98, 40,115, 44,
 32, 34, 40, 91, 94, 65, 45, 90, 97, 45,122, 48, 45, 57, 95, 93, 41, 34, 44, 32,
102,117,110, 99,116,105,111,110, 40, 99, 41, 10, 32, 32, 32, 32, 32, 32, 32, 32,
114,101,116,117,114,110, 32,115,116,114,105,110,103, 46,102,111,114,109, 97,116,
 40, 34, 37, 37, 37, 48, 50,120, 34, 44, 32,115,116,114,105,110,103, 46, 98,121,
116,101, 40, 99, 41, 41, 10, 32, 32, 32, 32,101,110,100, 41, 10,101,110,100, 10,
 10, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10, 45,
 45, 32, 80,114,111,116,101, 99,116,115, 32, 97, 32,112, 97,116,104, 32,115,101,
103,109,101,110,116, 44, 32,116,111, 32,112,114,101,118,101,110,116, 32,105,116,
 32,102,114,111,109, 32,105,110,116,101,114,102,101,114,105,110,103, 32,119,105,
116,104, 32,116,104,101, 10, 45, 45, 32,117,114,108, 32,112, 97,114,115,105,110,
103, 46, 10, 45, 45, 32, 73,110,112,117,116, 10, 45, 45, 32, 32, 32,115, 58, 32,
 98,105,110, 97,114,121, 32,115,116,114,105,110,103, 32,116,111, 32, 98,101, 32,
101,110, 99,111,100,101,100, 10, 45, 45, 32, 82,101,116,117,114,110,115, 10, 45,
 45, 32, 32, 32,101,115, 99, 97,112,101,100, 32,114,101,112,114,101,115,101,110,
116, 97,116,105,111,110, 32,111,102, 32,115,116,114,105,110,103, 32, 98,105,110,
 97,114,121, 10, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 10,108,111, 99, 97,108, 32,102,117,110, 99,116,105,111,110, 32,109, 97,107,
101, 95,115,101,116, 40,116, 41, 10,  9,108,111, 99, 97,108, 32,115, 32, 61, 32,
123,125, 10,  9,102,111,114, 32,105, 44,118, 32,105,110, 32, 98, 97,115,101, 46,
105,112, 97,105,114,115, 40,116, 41, 32,100,111, 10,  9,  9,115, 91,116, 91,105,
 93, 93, 32, 61, 32, 49, 10,  9,101,110,100, 10,  9,114,101,116,117,114,110, 32,
115, 10,101,110,100, 10, 10, 45, 45, 32,116,104,101,115,101, 32, 97,114,101, 32,
 97,108,108,111,119,101,100, 32,119,105,116,104,105,110,103, 32, 97, 32,112, 97,
116,104, 32,115,101,103,109,101,110,116, 44, 32, 97,108,111,110,103, 32,119,105,
116,104, 32, 97,108,112,104, 97,110,117,109, 10, 45, 45, 32,111,116,104,101,114,
 32, 99,104, 97,114, 97, 99,116,101,114,115, 32,109,117,115,116, 32, 98,101, 32,
101,115, 99, 97,112,101,100, 10,108,111, 99, 97,108, 32,115,101,103,109,101,110,
116, 95,115,101,116, 32, 61, 32,109, 97,107,101, 95,115,101,116, 32,123, 10, 32,
 32, 32, 32, 34, 45, 34, 44, 32, 34, 95, 34, 44, 32, 34, 46, 34, 44, 32, 34, 33,
 34, 44, 32, 34,126, 34, 44, 32, 34, 42, 34, 44, 32, 34, 39, 34, 44, 32, 34, 40,
 34, 44, 10,  9, 34, 41, 34, 44, 32, 34, 58, 34, 44, 32, 34, 64, 34, 44, 32, 34,
 38, 34, 44, 32, 34, 61, 34, 44, 32, 34, 43, 34, 44, 32, 34, 36, 34, 44, 32, 34,
 44, 34, 44, 10,125, 10, 10,108,111, 99, 97,108, 32,102,117,110, 99,116,105,111,
110, 32,112,114,111,116,101, 99,116, 95,115,101,103,109,101,110,116, 40,115, 41,
 10,  9,114,101,116,117,114,110, 32,115,116,114,105,110,103, 46,103,115,117, 98,
 40,115, 44, 32, 34, 40, 91, 94, 65, 45, 90, 97, 45,122, 48, 45, 57, 95, 93, 41,
 34, 44, 32,102,117,110, 99,116,105,111,110, 32, 40, 99, 41, 10,  9,  9,105,102,
 32,115,101,103,109,101,110,116, 95,115,101,116, 91, 99, 93, 32,116,104,101,110,
 32,114,101,116,117,114,110, 32, 99, 10,  9,  9,101,108,115,101, 32,114,101,116,
117,114,110, 32,115,116,114,105,110,103, 46,102,111,114,109, 97,116, 40, 34, 37,
 37, 37, 48, 50,120, 34, 44, 32,115,116,114,105,110,103, 46, 98,121,116,101, 40,
 99, 41, 41, 32,101,110,100, 10,  9,101,110,100, 41, 10,101,110,100, 10, 10, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10, 45, 45, 32,
 69,110, 99,111,100,101,115, 32, 97, 32,115,116,114,105,110,103, 32,105,110,116,
111, 32,105,116,115, 32,101,115, 99, 97,112,101,100, 32,104,101,120, 97,100,101,
 99,105,109, 97,108, 32,114,101,112,114,101,115,101,110,116, 97,116,105,111,110,
 10, 45, 45, 32, 73,110,112,117,116, 10, 45, 45, 32, 32, 32,115, 58, 32, 98,105,
110, 97,114,121, 32,115,116,114,105,110,103, 32,116,111, 32, 98,101, 32,101,110,
 99,111,100,101,100, 10, 45, 45, 32, 82,101,116,117,114,110,115, 10, 45, 45, 32,
 32, 32,101,115, 99, 97,112,101,100, 32,114,101,112,114,101,115,101,110,116, 97,
116,105,111,110, 32,111,102, 32,115,116,114,105,110,103, 32, 98,105,110, 97,114,
121, 10, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10,
102,117,110, 99,116,105,111,110, 32,117,110,101,115, 99, 97,112,101, 40,115, 41,
 10, 32, 32, 32, 32,114,101,116,117,114,110, 32,115,116,114,105,110,103, 46,103,
115,117, 98, 40,115, 44, 32, 34, 37, 37, 40, 37,120, 37,120, 41, 34, 44, 32,102,
117,110, 99,116,105,111,110, 40,104,101,120, 41, 10, 32, 32, 32, 32, 32, 32, 32,
 32,114,101,116,117,114,110, 32,115,116,114,105,110,103, 46, 99,104, 97,114, 40,
 98, 97,115,101, 46,116,111,110,117,109, 98,101,114, 40,104,101,120, 44, 32, 49,
 54, 41, 41, 10, 32, 32, 32, 32,101,110,100, 41, 10,101,110,100, 10, 10, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10, 45, 45, 32, 66,
117,105,108,100,115, 32, 97, 32,112, 97,116,104, 32,102,114,111,109, 32, 97, 32,
 98, 97,115,101, 32,112, 97,116,104, 32, 97,110,100, 32, 97, 32,114,101,108, 97,
116,105,118,101, 32,112, 97,116,104, 10, 45, 45, 32, 73,110,112,117,116, 10, 45,
 45, 32, 32, 32, 98, 97,115,101, 95,112, 97,116,104, 10, 45, 45, 32, 32, 32,114,
101,108, 97,116,105,118,101, 95,112, 97,116,104, 10, 45, 45, 32, 82,101,116,117,
114,110,115, 10, 45, 45, 32, 32, 32, 99,111,114,114,101,115,112,111,110,100,105,
110,103, 32, 97, 98,115,111,108,117,116,101, 32,112, 97,116,104, 10, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10,108,111, 99, 97,108,
 32,102,117,110, 99,116,105,111,110, 32, 97, 98,115,111,108,117,116,101, 95,112,
 97,116,104, 40, 98, 97,115,101, 95,112, 97,116,104, 44, 32,114,101,108, 97,116,
105,118,101, 95,112, 97,116,104, 41, 10, 32, 32, 32, 32,105,102, 32,115,116,114,
105,110,103, 46,115,117, 98, 40,114,101,108, 97,116,105,118,101, 95,112, 97,116,
104, 44, 32, 49, 44, 32, 49, 41, 32, 61, 61, 32, 34, 47, 34, 32,116,104,101,110,
 32,114,101,116,117,114,110, 32,114,101,108, 97,116,105,118,101, 95,112, 97,116,
104, 32,101,110,100, 10, 32, 32, 32, 32,108,111, 99, 97,108, 32,112, 97,116,104,
 32, 61, 32,115,116,114,105,110,103, 46,103,115,117, 98, 40, 98, 97,115,101, 95,
112, 97,116,104, 44, 32, 34, 91, 94, 47, 93, 42, 36, 34, 44, 32, 34, 34, 41, 10,
 32, 32, 32, 32,112, 97,116,104, 32, 61, 32,112, 97,116,104, 32, 46, 46, 32,114,
101,108, 97,116,105,118,101, 95,112, 97,116,104, 10, 32, 32, 32, 32,112, 97,116,
104, 32, 61, 32,115,116,114,105,110,103, 46,103,115,117, 98, 40,112, 97,116,104,
 44, 32, 34, 40, 91, 94, 47, 93, 42, 37, 46, 47, 41, 34, 44, 32,102,117,110, 99,
116,105,111,110, 32, 40,115, 41, 10, 32, 32, 32, 32, 32, 32, 32, 32,105,102, 32,
115, 32,126, 61, 32, 34, 46, 47, 34, 32,116,104,101,110, 32,114,101,116,117,114,
110, 32,115, 32,101,108,115,101, 32,114,101,116,117,114,110, 32, 34, 34, 32,101,
110,100, 10, 32, 32, 32, 32,101,110,100, 41, 10, 32, 32, 32, 32,112, 97,116,104,
 32, 61, 32,115,116,114,105,110,103, 46,103,115,117, 98, 40,112, 97,116,104, 44,
 32, 34, 47, 37, 46, 36, 34, 44, 32, 34, 47, 34, 41, 10, 32, 32, 32, 32,108,111,
 99, 97,108, 32,114,101,100,117, 99,101,100, 10, 32, 32, 32, 32,119,104,105,108,
101, 32,114,101,100,117, 99,101,100, 32,126, 61, 32,112, 97,116,104, 32,100,111,
 10, 32, 32, 32, 32, 32, 32, 32, 32,114,101,100,117, 99,101,100, 32, 61, 32,112,
 97,116,104, 10, 32, 32, 32, 32, 32, 32, 32, 32,112, 97,116,104, 32, 61, 32,115,
116,114,105,110,103, 46,103,115,117, 98, 40,114,101,100,117, 99,101,100, 44, 32,
 34, 40, 91, 94, 47, 93, 42, 47, 37, 46, 37, 46, 47, 41, 34, 44, 32,102,117,110,
 99,116,105,111,110, 32, 40,115, 41, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32,105,102, 32,115, 32,126, 61, 32, 34, 46, 46, 47, 46, 46, 47, 34, 32,116,
104,101,110, 32,114,101,116,117,114,110, 32, 34, 34, 32,101,108,115,101, 32,114,
101,116,117,114,110, 32,115, 32,101,110,100, 10, 32, 32, 32, 32, 32, 32, 32, 32,
101,110,100, 41, 10, 32, 32, 32, 32,101,110,100, 10, 32, 32, 32, 32,112, 97,116,
104, 32, 61, 32,115,116,114,105,110,103, 46,103,115,117, 98, 40,114,101,100,117,
 99,101,100, 44, 32, 34, 40, 91, 94, 47, 93, 42, 47, 37, 46, 37, 46, 41, 36, 34,
 44, 32,102,117,110, 99,116,105,111,110, 32, 40,115, 41, 10, 32, 32, 32, 32, 32,
 32, 32, 32,105,102, 32,115, 32,126, 61, 32, 34, 46, 46, 47, 46, 46, 34, 32,116,
104,101,110, 32,114,101,116,117,114,110, 32, 34, 34, 32,101,108,115,101, 32,114,
101,116,117,114,110, 32,115, 32,101,110,100, 10, 32, 32, 32, 32,101,110,100, 41,
 10, 32, 32, 32, 32,114,101,116,117,114,110, 32,112, 97,116,104, 10,101,110,100,
 10, 10, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10,
 45, 45, 32, 80, 97,114,115,101,115, 32, 97, 32,117,114,108, 32, 97,110,100, 32,
114,101,116,117,114,110,115, 32, 97, 32,116, 97, 98,108,101, 32,119,105,116,104,
 32, 97,108,108, 32,105,116,115, 32,112, 97,114,116,115, 32, 97, 99, 99,111,114,
100,105,110,103, 32,116,111, 32, 82, 70, 67, 32, 50, 51, 57, 54, 10, 45, 45, 32,
 84,104,101, 32,102,111,108,108,111,119,105,110,103, 32,103,114, 97,109,109, 97,
114, 32,100,101,115, 99,114,105, 98,101,115, 32,116,104,101, 32,110, 97,109,101,
115, 32,103,105,118,101,110, 32,116,111, 32,116,104,101, 32, 85, 82, 76, 32,112,
 97,114,116,115, 10, 45, 45, 32, 60,117,114,108, 62, 32, 58, 58, 61, 32, 60,115,
 99,104,101,109,101, 62, 58, 47, 47, 60, 97,117,116,104,111,114,105,116,121, 62,
 47, 60,112, 97,116,104, 62, 59, 60,112, 97,114, 97,109,115, 62, 63, 60,113,117,
101,114,121, 62, 35, 60,102,114, 97,103,109,101,110,116, 62, 10, 45, 45, 32, 60,
 97,117,116,104,111,114,105,116,121, 62, 32, 58, 58, 61, 32, 60,117,115,101,114,
105,110,102,111, 62, 64, 60,104,111,115,116, 62, 58, 60,112,111,114,116, 62, 10,
 45, 45, 32, 60,117,115,101,114,105,110,102,111, 62, 32, 58, 58, 61, 32, 60,117,
115,101,114, 62, 91, 58, 60,112, 97,115,115,119,111,114,100, 62, 93, 10, 45, 45,
 32, 60,112, 97,116,104, 62, 32, 58, 58, 32, 61, 32,123, 60,115,101,103,109,101,
110,116, 62, 47,125, 60,115,101,103,109,101,110,116, 62, 10, 45, 45, 32, 73,110,
112,117,116, 10, 45, 45, 32, 32, 32,117,114,108, 58, 32,117,110,105,102,111,114,
109, 32,114,101,115,111,117,114, 99,101, 32,108,111, 99, 97,116,111,114, 32,111,
102, 32,114,101,113,117,101,115,116, 10, 45, 45, 32, 32, 32,100,101,102, 97,117,
108,116, 58, 32,116, 97, 98,108,101, 32,119,105,116,104, 32,100,101,102, 97,117,
108,116, 32,118, 97,108,117,101,115, 32,102,111,114, 32,101, 97, 99,104, 32,102,
105,101,108,100, 10, 45, 45, 32, 82,101,116,117,114,110,115, 10, 45, 45, 32, 32,
 32,116, 97, 98,108,101, 32,119,105,116,104, 32,116,104,101, 32,102,111,108,108,
111,119,105,110,103, 32,102,105,101,108,100,115, 44, 32,119,104,101,114,101, 32,
 82, 70, 67, 32,110, 97,109,105,110,103, 32, 99,111,110,118,101,110,116,105,111,
110,115, 32,104, 97,118,101, 10, 45, 45, 32, 32, 32, 98,101,101,110, 32,112,114,
101,115,101,114,118,101,100, 58, 10, 45, 45, 32, 32, 32, 32, 32,115, 99,104,101,
109,101, 44, 32, 97,117,116,104,111,114,105,116,121, 44, 32,117,115,101,114,105,
110,102,111, 44, 32,117,115,101,114, 44, 32,112, 97,115,115,119,111,114,100, 44,
 32,104,111,115,116, 44, 32,112,111,114,116, 44, 10, 45, 45, 32, 32, 32, 32, 32,
112, 97,116,104, 44, 32,112, 97,114, 97,109,115, 44, 32,113,117,101,114,121, 44,
 32,102,114, 97,103,109,101,110,116, 10, 45, 45, 32, 79, 98,115, 58, 10, 45, 45,
 32, 32, 32,116,104,101, 32,108,101, 97,100,105,110,103, 32, 39, 47, 39, 32,105,
110, 32,123, 47, 60,112, 97,116,104, 62,125, 32,105,115, 32, 99,111,110,115,105,
100,101,114,101,100, 32,112, 97,114,116, 32,111,102, 32, 60,112, 97,116,104, 62,
 10, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10,102,
117,110, 99,116,105,111,110, 32,112, 97,114,115,101, 40,117,114,108, 44, 32,100,
101,102, 97,117,108,116, 41, 10, 32, 32, 32, 32, 45, 45, 32,105,110,105,116,105,
 97,108,105,122,101, 32,100,101,102, 97,117,108,116, 32,112, 97,114, 97,109,101,
116,101,114,115, 10, 32, 32, 32, 32,108,111, 99, 97,108, 32,112, 97,114,115,101,
100, 32, 61, 32,123,125, 10, 32, 32, 32, 32,102,111,114, 32,105, 44,118, 32,105,
110, 32, 98, 97,115,101, 46,112, 97,105,114,115, 40,100,101,102, 97,117,108,116,
 32,111,114, 32,112, 97,114,115,101,100, 41, 32,100,111, 32,112, 97,114,115,101,
100, 91,105, 93, 32, 61, 32,118, 32,101,110,100, 10, 32, 32, 32, 32, 45, 45, 32,
101,109,112,116,121, 32,117,114,108, 32,105,115, 32,112, 97,114,115,101,100, 32,
116,111, 32,110,105,108, 10, 32, 32, 32, 32,105,102, 32,110,111,116, 32,117,114,
108, 32,111,114, 32,117,114,108, 32, 61, 61, 32, 34, 34, 32,116,104,101,110, 32,
114,101,116,117,114,110, 32,110,105,108, 44, 32, 34,105,110,118, 97,108,105,100,
 32,117,114,108, 34, 32,101,110,100, 10, 32, 32, 32, 32, 45, 45, 32,114,101,109,
111,118,101, 32,119,104,105,116,101,115,112, 97, 99,101, 10, 32, 32, 32, 32, 45,
 45, 32,117,114,108, 32, 61, 32,115,116,114,105,110,103, 46,103,115,117, 98, 40,
117,114,108, 44, 32, 34, 37,115, 34, 44, 32, 34, 34, 41, 10, 32, 32, 32, 32, 45,
 45, 32,103,101,116, 32,102,114, 97,103,109,101,110,116, 10, 32, 32, 32, 32,117,
114,108, 32, 61, 32,115,116,114,105,110,103, 46,103,115,117, 98, 40,117,114,108,
 44, 32, 34, 35, 40, 46, 42, 41, 36, 34, 44, 32,102,117,110, 99,116,105,111,110,
 40,102, 41, 10, 32, 32, 32, 32, 32, 32, 32, 32,112, 97,114,115,101,100, 46,102,
114, 97,103,109,101,110,116, 32, 61, 32,102, 10, 32, 32, 32, 32, 32, 32, 32, 32,
114,101,116,117,114,110, 32, 34, 34, 10, 32, 32, 32, 32,101,110,100, 41, 10, 32,
 32, 32, 32, 45, 45, 32,103,101,116, 32,115, 99,104,101,109,101, 10, 32, 32, 32,
 32,117,114,108, 32, 61, 32,115,116,114,105,110,103, 46,103,115,117, 98, 40,117,
114,108, 44, 32, 34, 94, 40, 91, 37,119, 93, 91, 37,119, 37, 43, 37, 45, 37, 46,
 93, 42, 41, 37, 58, 34, 44, 10, 32, 32, 32, 32, 32, 32, 32, 32,102,117,110, 99,
116,105,111,110, 40,115, 41, 32,112, 97,114,115,101,100, 46,115, 99,104,101,109,
101, 32, 61, 32,115, 59, 32,114,101,116,117,114,110, 32, 34, 34, 32,101,110,100,
 41, 10, 32, 32, 32, 32, 45, 45, 32,103,101,116, 32, 97,117,116,104,111,114,105,
116,121, 10, 32, 32, 32, 32,117,114,108, 32, 61, 32,115,116,114,105,110,103, 46,
103,115,117, 98, 40,117,114,108, 44, 32, 34, 94, 47, 47, 40, 91, 94, 47, 93, 42,
 41, 34, 44, 32,102,117,110, 99,116,105,111,110, 40,110, 41, 10, 32, 32, 32, 32,
 32, 32, 32, 32,112, 97,114,115,101,100, 46, 97,117,116,104,111,114,105,116,121,
 32, 61, 32,110, 10, 32, 32, 32, 32, 32, 32, 32, 32,114,101,116,117,114,110, 32,
 34, 34, 10, 32, 32, 32, 32,101,110,100, 41, 10, 32, 32, 32, 32, 45, 45, 32,103,
101,116, 32,113,117,101,114,121, 32,115,116,114,105,110,103,105,110,103, 10, 32,
 32, 32, 32,117,114,108, 32, 61, 32,115,116,114,105,110,103, 46,103,115,117, 98,
 40,117,114,108, 44, 32, 34, 37, 63, 40, 46, 42, 41, 34, 44, 32,102,117,110, 99,
116,105,111,110, 40,113, 41, 10, 32, 32, 32, 32, 32, 32, 32, 32,112, 97,114,115,
101,100, 46,113,117,101,114,121, 32, 61, 32,113, 10, 32, 32, 32, 32, 32, 32, 32,
 32,114,101,116,117,114,110, 32, 34, 34, 10, 32, 32, 32, 32,101,110,100, 41, 10,
 32, 32, 32, 32, 45, 45, 32,103,101,116, 32,112, 97,114, 97,109,115, 10, 32, 32,
 32, 32,117,114,108, 32, 61, 32,115,116,114,105,110,103, 46,103,115,117, 98, 40,
117,114,108, 44, 32, 34, 37, 59, 40, 46, 42, 41, 34, 44, 32,102,117,110, 99,116,
105,111,110, 40,112, 41, 10, 32, 32, 32, 32, 32, 32, 32, 32,112, 97,114,115,101,
100, 46,112, 97,114, 97,109,115, 32, 61, 32,112, 10, 32, 32, 32, 32, 32, 32, 32,
 32,114,101,116,117,114,110, 32, 34, 34, 10, 32, 32, 32, 32,101,110,100, 41, 10,
 32, 32, 32, 32, 45, 45, 32,112, 97,116,104, 32,105,115, 32,119,104, 97,116,101,
118,101,114, 32,119, 97,115, 32,108,101,102,116, 10, 32, 32, 32, 32,105,102, 32,
117,114,108, 32,126, 61, 32, 34, 34, 32,116,104,101,110, 32,112, 97,114,115,101,
100, 46,112, 97,116,104, 32, 61, 32,117,114,108, 32,101,110,100, 10, 32, 32, 32,
 32,108,111, 99, 97,108, 32, 97,117,116,104,111,114,105,116,121, 32, 61, 32,112,
 97,114,115,101,100, 46, 97,117,116,104,111,114,105,116,121, 10, 32, 32, 32, 32,
105,102, 32,110,111,116, 32, 97,117,116,104,111,114,105,116,121, 32,116,104,101,
110, 32,114,101,116,117,114,110, 32,112, 97,114,115,101,100, 32,101,110,100, 10,
 32, 32, 32, 32, 97,117,116,104,111,114,105,116,121, 32, 61, 32,115,116,114,105,
110,103, 46,103,115,117, 98, 40, 97,117,116,104,111,114,105,116,121, 44, 34, 94,
 40, 91, 94, 64, 93, 42, 41, 64, 34, 44, 10, 32, 32, 32, 32, 32, 32, 32, 32,102,
117,110, 99,116,105,111,110, 40,117, 41, 32,112, 97,114,115,101,100, 46,117,115,
101,114,105,110,102,111, 32, 61, 32,117, 59, 32,114,101,116,117,114,110, 32, 34,
 34, 32,101,110,100, 41, 10, 32, 32, 32, 32, 97,117,116,104,111,114,105,116,121,
 32, 61, 32,115,116,114,105,110,103, 46,103,115,117, 98, 40, 97,117,116,104,111,
114,105,116,121, 44, 32, 34, 58, 40, 91, 94, 58, 93, 42, 41, 36, 34, 44, 10, 32,
 32, 32, 32, 32, 32, 32, 32,102,117,110, 99,116,105,111,110, 40,112, 41, 32,112,
 97,114,115,101,100, 46,112,111,114,116, 32, 61, 32,112, 59, 32,114,101,116,117,
114,110, 32, 34, 34, 32,101,110,100, 41, 10, 32, 32, 32, 32,105,102, 32, 97,117,
116,104,111,114,105,116,121, 32,126, 61, 32, 34, 34, 32,116,104,101,110, 32,112,
 97,114,115,101,100, 46,104,111,115,116, 32, 61, 32, 97,117,116,104,111,114,105,
116,121, 32,101,110,100, 10, 32, 32, 32, 32,108,111, 99, 97,108, 32,117,115,101,
114,105,110,102,111, 32, 61, 32,112, 97,114,115,101,100, 46,117,115,101,114,105,
110,102,111, 10, 32, 32, 32, 32,105,102, 32,110,111,116, 32,117,115,101,114,105,
110,102,111, 32,116,104,101,110, 32,114,101,116,117,114,110, 32,112, 97,114,115,
101,100, 32,101,110,100, 10, 32, 32, 32, 32,117,115,101,114,105,110,102,111, 32,
 61, 32,115,116,114,105,110,103, 46,103,115,117, 98, 40,117,115,101,114,105,110,
102,111, 44, 32, 34, 58, 40, 91, 94, 58, 93, 42, 41, 36, 34, 44, 10, 32, 32, 32,
 32, 32, 32, 32, 32,102,117,110, 99,116,105,111,110, 40,112, 41, 32,112, 97,114,
115,101,100, 46,112, 97,115,115,119,111,114,100, 32, 61, 32,112, 59, 32,114,101,
116,117,114,110, 32, 34, 34, 32,101,110,100, 41, 10, 32, 32, 32, 32,112, 97,114,
115,101,100, 46,117,115,101,114, 32, 61, 32,117,115,101,114,105,110,102,111, 10,
 32, 32, 32, 32,114,101,116,117,114,110, 32,112, 97,114,115,101,100, 10,101,110,
100, 10, 10, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 10, 45, 45, 32, 82,101, 98,117,105,108,100,115, 32, 97, 32,112, 97,114,115,101,
100, 32, 85, 82, 76, 32,102,114,111,109, 32,105,116,115, 32, 99,111,109,112,111,
110,101,110,116,115, 46, 10, 45, 45, 32, 67,111,109,112,111,110,101,110,116,115,
 32, 97,114,101, 32,112,114,111,116,101, 99,116,101,100, 32,105,102, 32, 97,110,
121, 32,114,101,115,101,114,118,101,100, 32,111,114, 32,117,110, 97,108,108,111,
119,101,100, 32, 99,104, 97,114, 97, 99,116,101,114,115, 32, 97,114,101, 32,102,
111,117,110,100, 10, 45, 45, 32, 73,110,112,117,116, 10, 45, 45, 32, 32, 32,112,
 97,114,115,101,100, 58, 32,112, 97,114,115,101,100, 32, 85, 82, 76, 44, 32, 97,
115, 32,114,101,116,117,114,110,101,100, 32, 98,121, 32,112, 97,114,115,101, 10,
 45, 45, 32, 82,101,116,117,114,110,115, 10, 45, 45, 32, 32, 32, 97, 32,115,116,
114,105,110,103,105,110,103, 32,119,105,116,104, 32,116,104,101, 32, 99,111,114,
114,101,115,112,111,110,100,105,110,103, 32, 85, 82, 76, 10, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10,102,117,110, 99,116,105,111,
110, 32, 98,117,105,108,100, 40,112, 97,114,115,101,100, 41, 10, 32, 32, 32, 32,
108,111, 99, 97,108, 32,112,112, 97,116,104, 32, 61, 32,112, 97,114,115,101, 95,
112, 97,116,104, 40,112, 97,114,115,101,100, 46,112, 97,116,104, 32,111,114, 32,
 34, 34, 41, 10, 32, 32, 32, 32,108,111, 99, 97,108, 32,117,114,108, 32, 61, 32,
 98,117,105,108,100, 95,112, 97,116,104, 40,112,112, 97,116,104, 41, 10, 32, 32,
 32, 32,105,102, 32,112, 97,114,115,101,100, 46,112, 97,114, 97,109,115, 32,116,
104,101,110, 32,117,114,108, 32, 61, 32,117,114,108, 32, 46, 46, 32, 34, 59, 34,
 32, 46, 46, 32,112, 97,114,115,101,100, 46,112, 97,114, 97,109,115, 32,101,110,
100, 10, 32, 32, 32, 32,105,102, 32,112, 97,114,115,101,100, 46,113,117,101,114,
121, 32,116,104,101,110, 32,117,114,108, 32, 61, 32,117,114,108, 32, 46, 46, 32,
 34, 63, 34, 32, 46, 46, 32,112, 97,114,115,101,100, 46,113,117,101,114,121, 32,
101,110,100, 10,  9,108,111, 99, 97,108, 32, 97,117,116,104,111,114,105,116,121,
 32, 61, 32,112, 97,114,115,101,100, 46, 97,117,116,104,111,114,105,116,121, 10,
  9,105,102, 32,112, 97,114,115,101,100, 46,104,111,115,116, 32,116,104,101,110,
 10,  9,  9, 97,117,116,104,111,114,105,116,121, 32, 61, 32,112, 97,114,115,101,
100, 46,104,111,115,116, 10,  9,  9,105,102, 32,112, 97,114,115,101,100, 46,112,
111,114,116, 32,116,104,101,110, 32, 97,117,116,104,111,114,105,116,121, 32, 61,
 32, 97,117,116,104,111,114,105,116,121, 32, 46, 46, 32, 34, 58, 34, 32, 46, 46,
 32,112, 97,114,115,101,100, 46,112,111,114,116, 32,101,110,100, 10,  9,  9,108,
111, 99, 97,108, 32,117,115,101,114,105,110,102,111, 32, 61, 32,112, 97,114,115,
101,100, 46,117,115,101,114,105,110,102,111, 10,  9,  9,105,102, 32,112, 97,114,
115,101,100, 46,117,115,101,114, 32,116,104,101,110, 10,  9,  9,  9,117,115,101,
114,105,110,102,111, 32, 61, 32,112, 97,114,115,101,100, 46,117,115,101,114, 10,
  9,  9,  9,105,102, 32,112, 97,114,115,101,100, 46,112, 97,115,115,119,111,114,
100, 32,116,104,101,110, 10,  9,  9,  9,  9,117,115,101,114,105,110,102,111, 32,
 61, 32,117,115,101,114,105,110,102,111, 32, 46, 46, 32, 34, 58, 34, 32, 46, 46,
 32,112, 97,114,115,101,100, 46,112, 97,115,115,119,111,114,100, 10,  9,  9,  9,
101,110,100, 10,  9,  9,101,110,100, 10,  9,  9,105,102, 32,117,115,101,114,105,
110,102,111, 32,116,104,101,110, 32, 97,117,116,104,111,114,105,116,121, 32, 61,
 32,117,115,101,114,105,110,102,111, 32, 46, 46, 32, 34, 64, 34, 32, 46, 46, 32,
 97,117,116,104,111,114,105,116,121, 32,101,110,100, 10,  9,101,110,100, 10, 32,
 32, 32, 32,105,102, 32, 97,117,116,104,111,114,105,116,121, 32,116,104,101,110,
 32,117,114,108, 32, 61, 32, 34, 47, 47, 34, 32, 46, 46, 32, 97,117,116,104,111,
114,105,116,121, 32, 46, 46, 32,117,114,108, 32,101,110,100, 10, 32, 32, 32, 32,
105,102, 32,112, 97,114,115,101,100, 46,115, 99,104,101,109,101, 32,116,104,101,
110, 32,117,114,108, 32, 61, 32,112, 97,114,115,101,100, 46,115, 99,104,101,109,
101, 32, 46, 46, 32, 34, 58, 34, 32, 46, 46, 32,117,114,108, 32,101,110,100, 10,
 32, 32, 32, 32,105,102, 32,112, 97,114,115,101,100, 46,102,114, 97,103,109,101,
110,116, 32,116,104,101,110, 32,117,114,108, 32, 61, 32,117,114,108, 32, 46, 46,
 32, 34, 35, 34, 32, 46, 46, 32,112, 97,114,115,101,100, 46,102,114, 97,103,109,
101,110,116, 32,101,110,100, 10, 32, 32, 32, 32, 45, 45, 32,117,114,108, 32, 61,
 32,115,116,114,105,110,103, 46,103,115,117, 98, 40,117,114,108, 44, 32, 34, 37,
115, 34, 44, 32, 34, 34, 41, 10, 32, 32, 32, 32,114,101,116,117,114,110, 32,117,
114,108, 10,101,110,100, 10, 10, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 10, 45, 45, 32, 66,117,105,108,100,115, 32, 97, 32, 97, 98,
115,111,108,117,116,101, 32, 85, 82, 76, 32,102,114,111,109, 32, 97, 32, 98, 97,
115,101, 32, 97,110,100, 32, 97, 32,114,101,108, 97,116,105,118,101, 32, 85, 82,
 76, 32, 97, 99, 99,111,114,100,105,110,103, 32,116,111, 32, 82, 70, 67, 32, 50,
 51, 57, 54, 10, 45, 45, 32, 73,110,112,117,116, 10, 45, 45, 32, 32, 32, 98, 97,
115,101, 95,117,114,108, 10, 45, 45, 32, 32, 32,114,101,108, 97,116,105,118,101,
 95,117,114,108, 10, 45, 45, 32, 82,101,116,117,114,110,115, 10, 45, 45, 32, 32,
 32, 99,111,114,114,101,115,112,111,110,100,105,110,103, 32, 97, 98,115,111,108,
117,116,101, 32,117,114,108, 10, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 10,102,117,110, 99,116,105,111,110, 32, 97, 98,115,111,108,
117,116,101, 40, 98, 97,115,101, 95,117,114,108, 44, 32,114,101,108, 97,116,105,
118,101, 95,117,114,108, 41, 10, 32, 32, 32, 32,105,102, 32, 98, 97,115,101, 46,
116,121,112,101, 40, 98, 97,115,101, 95,117,114,108, 41, 32, 61, 61, 32, 34,116,
 97, 98,108,101, 34, 32,116,104,101,110, 10, 32, 32, 32, 32, 32, 32, 32, 32, 98,
 97,115,101, 95,112, 97,114,115,101,100, 32, 61, 32, 98, 97,115,101, 95,117,114,
108, 10, 32, 32, 32, 32, 32, 32, 32, 32, 98, 97,115,101, 95,117,114,108, 32, 61,
 32, 98,117,105,108,100, 40, 98, 97,115,101, 95,112, 97,114,115,101,100, 41, 10,
 32, 32, 32, 32,101,108,115,101, 10, 32, 32, 32, 32, 32, 32, 32, 32, 98, 97,115,
101, 95,112, 97,114,115,101,100, 32, 61, 32,112, 97,114,115,101, 40, 98, 97,115,
101, 95,117,114,108, 41, 10, 32, 32, 32, 32,101,110,100, 10, 32, 32, 32, 32,108,
111, 99, 97,108, 32,114,101,108, 97,116,105,118,101, 95,112, 97,114,115,101,100,
 32, 61, 32,112, 97,114,115,101, 40,114,101,108, 97,116,105,118,101, 95,117,114,
108, 41, 10, 32, 32, 32, 32,105,102, 32,110,111,116, 32, 98, 97,115,101, 95,112,
 97,114,115,101,100, 32,116,104,101,110, 32,114,101,116,117,114,110, 32,114,101,
108, 97,116,105,118,101, 95,117,114,108, 10, 32, 32, 32, 32,101,108,115,101,105,
102, 32,110,111,116, 32,114,101,108, 97,116,105,118,101, 95,112, 97,114,115,101,
100, 32,116,104,101,110, 32,114,101,116,117,114,110, 32, 98, 97,115,101, 95,117,
114,108, 10, 32, 32, 32, 32,101,108,115,101,105,102, 32,114,101,108, 97,116,105,
118,101, 95,112, 97,114,115,101,100, 46,115, 99,104,101,109,101, 32,116,104,101,
110, 32,114,101,116,117,114,110, 32,114,101,108, 97,116,105,118,101, 95,117,114,
108, 10, 32, 32, 32, 32,101,108,115,101, 10, 32, 32, 32, 32, 32, 32, 32, 32,114,
101,108, 97,116,105,118,101, 95,112, 97,114,115,101,100, 46,115, 99,104,101,109,
101, 32, 61, 32, 98, 97,115,101, 95,112, 97,114,115,101,100, 46,115, 99,104,101,
109,101, 10, 32, 32, 32, 32, 32, 32, 32, 32,105,102, 32,110,111,116, 32,114,101,
108, 97,116,105,118,101, 95,112, 97,114,115,101,100, 46, 97,117,116,104,111,114,
105,116,121, 32,116,104,101,110, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32,114,101,108, 97,116,105,118,101, 95,112, 97,114,115,101,100, 46, 97,117,116,
104,111,114,105,116,121, 32, 61, 32, 98, 97,115,101, 95,112, 97,114,115,101,100,
 46, 97,117,116,104,111,114,105,116,121, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32,105,102, 32,110,111,116, 32,114,101,108, 97,116,105,118,101, 95,112,
 97,114,115,101,100, 46,112, 97,116,104, 32,116,104,101,110, 10, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,114,101,108, 97,116,105,118,101,
 95,112, 97,114,115,101,100, 46,112, 97,116,104, 32, 61, 32, 98, 97,115,101, 95,
112, 97,114,115,101,100, 46,112, 97,116,104, 10, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32,105,102, 32,110,111,116, 32,114,101,108, 97,116,
105,118,101, 95,112, 97,114,115,101,100, 46,112, 97,114, 97,109,115, 32,116,104,
101,110, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32,114,101,108, 97,116,105,118,101, 95,112, 97,114,115,101,100, 46,112,
 97,114, 97,109,115, 32, 61, 32, 98, 97,115,101, 95,112, 97,114,115,101,100, 46,
112, 97,114, 97,109,115, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32,105,102, 32,110,111,116, 32,114,101,108, 97,116,105,
118,101, 95,112, 97,114,115,101,100, 46,113,117,101,114,121, 32,116,104,101,110,
 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32,114,101,108, 97,116,105,118,101, 95,112, 97,114,115,101,100,
 46,113,117,101,114,121, 32, 61, 32, 98, 97,115,101, 95,112, 97,114,115,101,100,
 46,113,117,101,114,121, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32,101,110,100, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32,101,110,100, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32,101,108,115,101, 32, 32, 32, 32, 10, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32,114,101,108, 97,116,105,118,101, 95,112, 97,114,
115,101,100, 46,112, 97,116,104, 32, 61, 32, 97, 98,115,111,108,117,116,101, 95,
112, 97,116,104, 40, 98, 97,115,101, 95,112, 97,114,115,101,100, 46,112, 97,116,
104, 32,111,114, 32, 34, 34, 44, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32, 32, 32, 32, 32, 32, 32,114,101,108, 97,116,105,118,101, 95,112, 97,
114,115,101,100, 46,112, 97,116,104, 41, 10, 32, 32, 32, 32, 32, 32, 32, 32, 32,
 32, 32, 32,101,110,100, 10, 32, 32, 32, 32, 32, 32, 32, 32,101,110,100, 10, 32,
 32, 32, 32, 32, 32, 32, 32,114,101,116,117,114,110, 32, 98,117,105,108,100, 40,
114,101,108, 97,116,105,118,101, 95,112, 97,114,115,101,100, 41, 10, 32, 32, 32,
 32,101,110,100, 10,101,110,100, 10, 10, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 10, 45, 45, 32, 66,114,101, 97,107,115, 32, 97, 32,
112, 97,116,104, 32,105,110,116,111, 32,105,116,115, 32,115,101,103,109,101,110,
116,115, 44, 32,117,110,101,115, 99, 97,112,105,110,103, 32,116,104,101, 32,115,
101,103,109,101,110,116,115, 10, 45, 45, 32, 73,110,112,117,116, 10, 45, 45, 32,
 32, 32,112, 97,116,104, 10, 45, 45, 32, 82,101,116,117,114,110,115, 10, 45, 45,
 32, 32, 32,115,101,103,109,101,110,116, 58, 32, 97, 32,116, 97, 98,108,101, 32,
119,105,116,104, 32,111,110,101, 32,101,110,116,114,121, 32,112,101,114, 32,115,
101,103,109,101,110,116, 10, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 10,102,117,110, 99,116,105,111,110, 32,112, 97,114,115,101, 95,
112, 97,116,104, 40,112, 97,116,104, 41, 10,  9,108,111, 99, 97,108, 32,112, 97,
114,115,101,100, 32, 61, 32,123,125, 10,  9,112, 97,116,104, 32, 61, 32,112, 97,
116,104, 32,111,114, 32, 34, 34, 10,  9, 45, 45,112, 97,116,104, 32, 61, 32,115,
116,114,105,110,103, 46,103,115,117, 98, 40,112, 97,116,104, 44, 32, 34, 37,115,
 34, 44, 32, 34, 34, 41, 10,  9,115,116,114,105,110,103, 46,103,115,117, 98, 40,
112, 97,116,104, 44, 32, 34, 40, 91, 94, 47, 93, 43, 41, 34, 44, 32,102,117,110,
 99,116,105,111,110, 32, 40,115, 41, 32,116, 97, 98,108,101, 46,105,110,115,101,
114,116, 40,112, 97,114,115,101,100, 44, 32,115, 41, 32,101,110,100, 41, 10,  9,
102,111,114, 32,105, 32, 61, 32, 49, 44, 32,116, 97, 98,108,101, 46,103,101,116,
110, 40,112, 97,114,115,101,100, 41, 32,100,111, 10,  9,  9,112, 97,114,115,101,
100, 91,105, 93, 32, 61, 32,117,110,101,115, 99, 97,112,101, 40,112, 97,114,115,
101,100, 91,105, 93, 41, 10,  9,101,110,100, 10,  9,105,102, 32,115,116,114,105,
110,103, 46,115,117, 98, 40,112, 97,116,104, 44, 32, 49, 44, 32, 49, 41, 32, 61,
 61, 32, 34, 47, 34, 32,116,104,101,110, 32,112, 97,114,115,101,100, 46,105,115,
 95, 97, 98,115,111,108,117,116,101, 32, 61, 32, 49, 32,101,110,100, 10,  9,105,
102, 32,115,116,114,105,110,103, 46,115,117, 98, 40,112, 97,116,104, 44, 32, 45,
 49, 44, 32, 45, 49, 41, 32, 61, 61, 32, 34, 47, 34, 32,116,104,101,110, 32,112,
 97,114,115,101,100, 46,105,115, 95,100,105,114,101, 99,116,111,114,121, 32, 61,
 32, 49, 32,101,110,100, 10,  9,114,101,116,117,114,110, 32,112, 97,114,115,101,
100, 10,101,110,100, 10, 10, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 10, 45, 45, 32, 66,117,105,108,100,115, 32, 97, 32,112, 97,116,
104, 32, 99,111,109,112,111,110,101,110,116, 32,102,114,111,109, 32,105,116,115,
 32,115,101,103,109,101,110,116,115, 44, 32,101,115, 99, 97,112,105,110,103, 32,
112,114,111,116,101, 99,116,101,100, 32, 99,104, 97,114, 97, 99,116,101,114,115,
 46, 10, 45, 45, 32, 73,110,112,117,116, 10, 45, 45, 32, 32, 32,112, 97,114,115,
101,100, 58, 32,112, 97,116,104, 32,115,101,103,109,101,110,116,115, 10, 45, 45,
 32, 32, 32,117,110,115, 97,102,101, 58, 32,105,102, 32,116,114,117,101, 44, 32,
115,101,103,109,101,110,116,115, 32, 97,114,101, 32,110,111,116, 32,112,114,111,
116,101, 99,116,101,100, 32, 98,101,102,111,114,101, 32,112, 97,116,104, 32,105,
115, 32, 98,117,105,108,116, 10, 45, 45, 32, 82,101,116,117,114,110,115, 10, 45,
 45, 32, 32, 32,112, 97,116,104, 58, 32, 99,111,114,114,101,115,112,111,110,100,
105,110,103, 32,112, 97,116,104, 32,115,116,114,105,110,103,105,110,103, 10, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45,
 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 45, 10,102,117,110,
 99,116,105,111,110, 32, 98,117,105,108,100, 95,112, 97,116,104, 40,112, 97,114,
115,101,100, 44, 32,117,110,115, 97,102,101, 41, 10,  9,108,111, 99, 97,108, 32,
112, 97,116,104, 32, 61, 32, 34, 34, 10,  9,108,111, 99, 97,108, 32,110, 32, 61,
 32,116, 97, 98,108,101, 46,103,101,116,110, 40,112, 97,114,115,101,100, 41, 10,
  9,105,102, 32,117,110,115, 97,102,101, 32,116,104,101,110, 10,  9,  9,102,111,
114, 32,105, 32, 61, 32, 49, 44, 32,110, 45, 49, 32,100,111, 10,  9,  9,  9,112,
 97,116,104, 32, 61, 32,112, 97,116,104, 32, 46, 46, 32,112, 97,114,115,101,100,
 91,105, 93, 10,  9,  9,  9,112, 97,116,104, 32, 61, 32,112, 97,116,104, 32, 46,
 46, 32, 34, 47, 34, 10,  9,  9,101,110,100, 10,  9,  9,105,102, 32,110, 32, 62,
 32, 48, 32,116,104,101,110, 10,  9,  9,  9,112, 97,116,104, 32, 61, 32,112, 97,
116,104, 32, 46, 46, 32,112, 97,114,115,101,100, 91,110, 93, 10,  9,  9,  9,105,
102, 32,112, 97,114,115,101,100, 46,105,115, 95,100,105,114,101, 99,116,111,114,
121, 32,116,104,101,110, 32,112, 97,116,104, 32, 61, 32,112, 97,116,104, 32, 46,
 46, 32, 34, 47, 34, 32,101,110,100, 10,  9,  9,101,110,100, 10,  9,101,108,115,
101, 10,  9,  9,102,111,114, 32,105, 32, 61, 32, 49, 44, 32,110, 45, 49, 32,100,
111, 10,  9,  9,  9,112, 97,116,104, 32, 61, 32,112, 97,116,104, 32, 46, 46, 32,
112,114,111,116,101, 99,116, 95,115,101,103,109,101,110,116, 40,112, 97,114,115,
101,100, 91,105, 93, 41, 10,  9,  9,  9,112, 97,116,104, 32, 61, 32,112, 97,116,
104, 32, 46, 46, 32, 34, 47, 34, 10,  9,  9,101,110,100, 10,  9,  9,105,102, 32,
110, 32, 62, 32, 48, 32,116,104,101,110, 10,  9,  9,  9,112, 97,116,104, 32, 61,
 32,112, 97,116,104, 32, 46, 46, 32,112,114,111,116,101, 99,116, 95,115,101,103,
109,101,110,116, 40,112, 97,114,115,101,100, 91,110, 93, 41, 10,  9,  9,  9,105,
102, 32,112, 97,114,115,101,100, 46,105,115, 95,100,105,114,101, 99,116,111,114,
121, 32,116,104,101,110, 32,112, 97,116,104, 32, 61, 32,112, 97,116,104, 32, 46,
 46, 32, 34, 47, 34, 32,101,110,100, 10,  9,  9,101,110,100, 10,  9,101,110,100,
 10,  9,105,102, 32,112, 97,114,115,101,100, 46,105,115, 95, 97, 98,115,111,108,
117,116,101, 32,116,104,101,110, 32,112, 97,116,104, 32, 61, 32, 34, 47, 34, 32,
 46, 46, 32,112, 97,116,104, 32,101,110,100, 10,  9,114,101,116,117,114,110, 32,
112, 97,116,104, 10,101,110,100, 10,
};

 if (luaL_loadbuffer(L,(const char*)B1,sizeof(B1),"url.lua")==0) lua_call(L, 0, 0);
}
